import { NavLink } from "react-router-dom";
import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Navb.css";

function NavbFooter(props) {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <ul style={{fontSize:'1.5rem'}}>
              <li>Inicio</li>
              <li>Servicios</li>
              <li>Convenios</li>
              <li>Socios</li>
              <li>Nosotros</li>
              <li>Contacto</li>
            </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <div id="footer-bar" className="footer-bar footer-bar-detached">
        <NavLink exact to="/">
          <AiIcons.AiOutlineHome className="font-17" />
          <span>Inicio</span>
        </NavLink>
        <NavLink exact to="/">
          <AiIcons.AiOutlineShop className="font-17" />
          <span>Servicios</span>
        </NavLink>
        <NavLink exact to="/">
          <BsIcons.BsHandbag className="font-17" />
          <span>Convenios</span>
        </NavLink>
        <NavLink exact to="/">
          <BsIcons.BsEnvelope className="font-17" />
          <span>Contacto</span>
        </NavLink>
        <div onClick={handleShow}>
          <BsIcons.BsGrid className="font-17" />
          <span>Menú</span>
        </div>
      </div>
    </>
  );
}

export default NavbFooter;
