import React, { useEffect, useState } from "react";
import './Simulator.css';
import axios from "axios";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { url } from "../../service/EndPoint";

function Simulator(props) {

  const [plan, setPlan] = useState(null);
  
  const [montoDesde, setMontoDesde] = useState(0);
  const [montoHasta, setMontoHasta] = useState(0);
  const [cuotasDesde, setCuotasDesde] = useState(0);
  const [cuotasHasta, setCuotasHasta] = useState(0);
  const [primerVencimiento, setPrimerVencimiento] = useState("");
  const [stepMonto, setStepMonto] = useState(0)
  const [stepCuota, setStepCuota] = useState(0)
  const [amount, setAmount] = useState(5000);
  const [days, setDays] = useState(3);
  const [valorCuota, setValorCuota] = useState("");
  const [tem, setTem] = useState(0);
  const [estado, setStado]= useState()

  function getData(){
    axios.post(url+'simulador/list.php')
    .then((getData) => {
      setMontoDesde(getData.data[0].montoDesde);
      setMontoHasta(getData.data[0].montoHasta);
      setCuotasDesde(getData.data[0].cuotasDesde);
      setCuotasHasta(getData.data[0].cuotasHasta);
      setStepMonto(getData.data[0].stepMonto);
      setStepCuota(getData.data[0].stepCuota);
      setTem(Number(getData.data[0].tem));
      
    });
  }

  useEffect(() => {  
    getData()
  }, []);

  useEffect(() => {
  
    simular(plan?.id, amount, days);
  }, [plan?.id, amount, days]);




  const simular = (planId, monto, cuotas) => {
    let a = tem * Math.pow(1 + tem, days);
    let b = Math.pow(1 + tem, days) - 1;
    let c = monto * (a / b);
    setValorCuota(Math.round(c));
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleDaysChange = (event) => {
    setDays(event.target.value);
  };

  return (

            <div className="row">
              <div className="col-md-7 mx-auto">
                <div className="card " style={{border: '0px'}}>
                <div className="card-body">
                    <div className="container">
                      <h2 className="card-title">
                        <strong />
                      </h2>
                      <div className="row g-3">
                        <div className="col-4">
                          <label>Pedís</label>
                          <input
                            type="text"
                            className="form-control res"
                            id="amount"
                            value={Number(amount).toLocaleString('es-ES')}
                            onChange={handleAmountChange}
                            readOnly
                          />
                        </div>
                        <div className="col-4">
                          <label>Cuotas</label>
                          <input
                            type="text"
                            className="form-control res"
                            id="days"
                            value={days}
                            onChange={handleDaysChange}
                            readOnly
                          />
                        </div>
                        <div className="col-4">
                          <label>
                            Valor Cuota
                          </label>
                          <input
                            type="text"
                            id="valorcuota"
                            className="form-control res"
                            value={valorCuota ? Number(valorCuota).toLocaleString('es-ES') : '-'}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container pt-2">
                      <div className="pt-1">
                        <label htmlFor="customRange3" className="form-label">
                          <h4>
                            Monto desde{' '}
                            <strong>
                              <span id="monto_desde">{Number(montoDesde).toLocaleString('es-ES')}</span>
                            </strong>{" "}
                            a{' '}
                            <strong>
                              <span id="monto_hasta">{Number(montoHasta).toLocaleString('es-ES')}</span>
                            </strong>
                          </h4>
                        </label>
                        <input
                          type="range"
                          className="form-range"
                          min={montoDesde}
                          max={montoHasta}
                          step={stepMonto}
                          id="customRange3"
                          value={amount && amount}
                          onChange={handleAmountChange}
                        />
                      </div>
                      <div className="pt-2">
                        <label htmlFor="customRange4" className="form-label">
                          <h4>
                            De{" "}
                            <strong>
                              <span id="cuotas_desde">{cuotasDesde}</span>
                            </strong>{" "}
                            a
                            <strong>{' '}
                              <span id="cuotas_hasta">{cuotasHasta}</span>
                            </strong>{" "}
                            cuotas
                          </h4>
                        </label>
                        <input
                          type="range"
                          className="form-range"
                          min={cuotasDesde}
                          max={cuotasHasta}
                          step={stepCuota}
                          id="customRange4"
                          value={days}
                          onChange={handleDaysChange}
                        />
                      </div>
                      
                    </div>
                  </div>
                  <div className="pt-3 text-center">
                    <NavLink exact to={'/ayuda-economica/'+encodeURIComponent(
                                Number(amount)
                            )}>
                      <div className="btn app-button text-white">
                        <span>Solicitar</span>
                      </div>
                    </NavLink>
                  </div>
                  <br />
                </div>
              </div>
            </div>
        
  );
}
export default Simulator;