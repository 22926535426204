import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { url } from "../../service/EndPoint";
import { Form, FloatingLabel, Button } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./Identidad.css";

const Identidad = ({}) => {



  const [formData, setFormData] = useState({
    direccion: "",
    telefono: "",
    telefonoWhatsapp: "",
    textoAnimado: "",
    quienes: "",
    cuit: "",
    razonSocial: "",
    condicion: "",
    horario:"",
    email:""
  });

  function getData(){
	axios
	.post(url + "identidad/datos.php")
	.then((getData) => {
	  setFormData(getData.data[0]);
	});
  }
  useEffect(() => {
	getData()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(url + `identidad/editar.php`, formData)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
        window.alert('Actualizado')
        }
      })
      .catch(function (res) {});
  };

  const handleEditorChange = (content) => {
    setFormData({...formData, quienes: content});
  };

  return (
    <div className="identidad">
      <div className="container">
        <div className="text-start">
          <NavLink exact to={"/dashboard"}>
            <h1 className="display-1">
              <BsIcons.BsArrowLeftShort className="app-color" />
            </h1>
          </NavLink>
        </div>
        <div
          className="card shadow mb-3"
          style={{ background: "#292740", border: "0px" }}
        >
          <div className="card-body">
            <div className="text-center text-white">Identidad del Sitio</div>
          </div>
        </div>
        <div className="text-center " style={{ color: "#292740" }}></div>

        <Form onSubmit={handleSubmit}>
          <FloatingLabel controlId="direccion" label="Dirección">
            <Form.Control
              type="text"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>

          <FloatingLabel controlId="telefono" label="Teléfono">
            <Form.Control
              type="tel"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>
          <FloatingLabel controlId="horario" label="Horario">
            <Form.Control
              type="text"
              name="horario"
              value={formData.horario}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>
          <FloatingLabel controlId="email" label="email">
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>

          <FloatingLabel controlId="telefonoWhatsapp" label="Teléfono Whatsapp">
            <Form.Control
              type="tel"
              name="telefonoWhatsapp"
              value={formData.telefonoWhatsapp}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>

          <FloatingLabel controlId="textoAnimado" label="Texto Animado">
            <Form.Control
              type="text"
              name="textoAnimado"
              value={formData.textoAnimado}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>
        <div className="mb-3">
         <p>Quienes somos</p>
          <ReactQuill value={formData.quienes}   onChange={handleEditorChange} />
        </div>
          <FloatingLabel controlId="cuit" label="CUIT">
            <Form.Control
              type="text"
              name="cuit"
              value={formData.cuit}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>

          <FloatingLabel controlId="razonSocial" label="Razón Social">
            <Form.Control
              type="text"
              name="razonSocial"
              value={formData.razonSocial}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>

          <FloatingLabel controlId="condicion" label="Condición">
            <Form.Control
              type="text"
              name="condicion"
              value={formData.condicion}
              onChange={handleChange}
			  className="mb-3"
            />
          </FloatingLabel>

		  <div className="d-grid gap-2 pt-2">
            <button
              type="submit"
              className="btn app-button  text-white shadow"
            >
              Guardar
            </button>
          </div>
        </Form><br/><br/><br/><br/>
      </div>
    </div>
  );
};

export default Identidad;
