import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useState } from "react";
import { url } from "../../service/EndPoint";

function DetalleModal({ show, onHide, data, actualizar }) {
  function formatearFecha(fecha) {
    if (!(fecha instanceof Date)) {
      fecha = new Date(fecha);
    }

    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    const diaFormateado = dia.toString().padStart(2, "0");
    const mesFormateado = mes.toString().padStart(2, "0");

    const fechaFormateada = `${diaFormateado}/${mesFormateado}/${año}`;

    return fechaFormateada;
  }
  useEffect(() => {
    setSolicitud(data);
  }, [data]);

  const [solicitud, setSolicitud] = useState("");

  const onChangeForm = (e) => {
    setSolicitud({ ...solicitud, [e.target.name]: e.target.value });
  };
  const [activar, setActivar] = useState(true);

  function enviar() {
    axios
      .post(url + `solicitudes/editarAlta.php`, solicitud)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          if (data.success === 1) {
            actualizar();
            onHide()
          }
        }
      })
      .catch(function (res) {});
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>Fecha solicitud {data && formatearFecha(data.create_at)}</div>
        </div>
        <form
          id="solicitudPrestamo"
          className="needs-validation"
          noValidate
          method="post"
        >
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="Nombre"
                    name="nombre"
                    value={solicitud && solicitud.nombre}
                    placeholder="Nombre"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="Nombre">Nombre</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="Apellido"
                    name="apellido"
                    value={solicitud && solicitud.apellido}
                    placeholder="Apellido"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="Apellido">Apellido</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="dni"
                    name="dni"
                    value={solicitud && solicitud.dni}
                    placeholder="Nº Documneto de Identidad"
                    pattern="[0-9]+"
                    minLength={6}
                    maxLength={10}
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="dni">Nº Documento de Identidad</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="date"
                    className="form-control"
                    id="fechaNacimiento"
                    name="fechaNacimiento"
                    value={solicitud && solicitud.fechaNacimiento}
                    placeholder="Fecha de Nacimiento"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="fechaNacimento">Fecha de Nacimiento</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="Direccion"
                    name="direccion"
                    value={solicitud && solicitud.direccion}
                    placeholder="Dirección"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="Direccion">Dirección</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="Localidad"
                    name="localidad"
                    value={solicitud && solicitud.localidad}
                    placeholder="Localidad"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="Localidadn">Localidad</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="Telefono"
                  name="telefono"
                  placeholder="Teléfono"
                  value={solicitud && solicitud.telefono}
                  pattern="[0-9]+"
                  minLength={6}
                  maxLength={15}
                  onChange={onChangeForm}
                  required
                />
                <label htmlFor="Telefono">Teléfono</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <input
                  type="mail"
                  className="form-control"
                  name="mail"
                  id="mail"
                  placeholder="Correo electrónico"
                  value={solicitud && solicitud.mail}
                  onChange={onChangeForm}
                  required
                />
                <label htmlFor="mail">Correo electrónico</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Estado"
                  name="estado"
                  onChange={onChangeForm}
                >
                  <option selected>
                    {Number(solicitud && solicitud.estado) === 0 && "Nuevo"}
                    {Number(solicitud && solicitud.estado) === 1 &&
                      "Pendiente"}{" "}
                    {Number(solicitud && solicitud.estado) === 2 && "Cerrada"}
                  </option>
                  <option value={0}>Nuevo</option>
                  <option value={1}>Pendiente</option>
                  <option value={2}>Cerrado</option>
                </select>
                <label htmlFor="floatingSelect">Estado</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <button
                className="btn app-button btn-block text-white"
                type="button"
                onClick={() => activar && enviar()}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DetalleModal;
