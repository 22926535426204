import React, { createContext, useEffect, useState } from "react";
import { url } from "../service/EndPoint";
import axios from "axios";

export const AuthContext = createContext();

// Define the base URL
const Axios = axios.create({
  baseURL: url + "auth/"
});

const AuthProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [theUser, setTheUser] = useState("");

  useEffect(() => {
    isLoggedIn();
  }, []);

  const toggleNav = () => {
    setShowLogin(!showLogin);
  };

  const logoutUser = () => {
    localStorage.clear();

    setIsAuth(false);
  };

  const loginUser = async (user) => {

      const login = await Axios.post("login.php", {
        email: user.email,
        password: user.password
      });

      setTheUser(login.data.nombre)
    return login.data;
  };

  const isLoggedIn = async () => {
    const loginToken = localStorage.getItem("loginToken");
    if (loginToken) {
      setIsAuth(true);
    }
  };

  const contextValue = {
    rootState: { showLogin, isAuth, theUser },
    toggleNav,
    isLoggedIn,
    loginUser,
    logoutUser
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
