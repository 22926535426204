import "./SolicitudPrestamo.css";
import { NavLink } from "react-router-dom";
import Image from "react-bootstrap/Image";
import {url} from "../../service/EndPoint"
import logo from "../../assets/image/logo_cabecera.png";
import axios from 'axios';
import { useState } from "react";

const SolicitudPrestamo = ({}) => {
  const ultimaParte = window.location.pathname.split("/").pop();
  const [info, setInfo] = useState(ultimaParte || "0");

  const onChangeMonto = (e) => {
    setInfo(e.target.value);
  };
  const [exito, setExito] = useState(true)
  const [solicitud, setSolicitud] = useState({
    monto: info,
    nombre: "",
    apellido: "",
    dni: "",
    fechaNacimiento: "",
    sexo: "",
    situacionLaboral: "",
    banco: "",
    telefono: "",
    mail: "",
  });

  const onChangeForm = (e) => {
    setSolicitud({ ...solicitud, [e.target.name]: e.target.value });

  };
  const [activar,setActivar]=useState(true)

  function enviar(){
    setActivar(false)
    if (
      solicitud.nombre === '' ||
      solicitud.apellido === '' ||
      solicitud.dni === '' ||
      solicitud.telefono === '' ||
      solicitud.mail === ''
    ) {
      setActivar(true)
      alert('Por favor, complete todos los campos.');
    } else {
    axios.post(url+`solicitudes/prestamo.php`, solicitud)
    .then((res) => {
          let {data} = res;                             
          if(data.success && data.message){              
              if(data.success === 1){ 
                 setExito(false)
              }
          }
        })
        .catch(function (res) {
          
        });
      }
      
  }

  return (
    <div className="solicitudprestamo">
      <div className="container">
        <header id="load">
          <div id="layoutDefault">
            <div id="layoutDefault_content">
              <nav
                className="navbar navbar-expand-lg navbar-dark  fixed-top d-none d-sm-none d-md-block"
                style={{ background: "#292740", color: "#fff !important" }}
              >
                <div className="container-fluid">
                  <a
                    className="navbar-brand d-none d-sm-none d-md-block"
                    href="#"
                  >
                    <Image src={logo} width="180px" />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarNav"
                  >
                    <div className="menu ">
                      <div className="menu menu-titulo">
                        <NavLink exact to="/">
                          <div className="nenu-item">INICIO</div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div className="d-none d-md-block d-sm-none">
          <br />
          <br />
          <br />
        </div>
        {exito ?
        <div className="container">
          <div className="row pt-2">
            <p className="titulo-1 text-center">
              Ingresá el monto que necesitás, tus datos y en breve nos
              comunicaremos con vos!!!
            </p>
            <div className="col-md-6 col-sm-12 mx-auto">
              <form
                id="solicitudPrestamo"
                className="needs-validation"
                noValidate
                method="post"
              >
                <div className="row">
                  <div className="row g-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="number"
                          id="monto"
                          name="monto"
                          className="form-control currency text-center"
                          min={1000}
                          max={250000}
                          placeholder="Monto"
                          value={info && info}
                          onChange={onChangeMonto}
                          required
                        />
                        <label htmlFor="monto">Monto</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row g-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="Nombre"
                          name="nombre"
                          placeholder="Nombre"
                          onChange={onChangeForm}
                          required
                        />
                        <label htmlFor="Nombre">Nombre</label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="Apellido"
                          name="apellido"
                          placeholder="Apellido"
                          onChange={onChangeForm}
                          required
                        />
                        <label htmlFor="Apellido">Apellido</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row g-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="dni"
                          name="dni"
                          placeholder="Nº Documneto de Identidad"
                          pattern="[0-9]+"
                          minLength={6}
                          maxLength={10}
                          onChange={onChangeForm}
                          required
                        />
                        <label htmlFor="dni">Nº Documento de Identidad</label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="date"
                          className="form-control"
                          id="fechaNacimiento"
                          name="fechaNacimiento"
                          placeholder="Fecha de Nacimiento"
                          onChange={onChangeForm}
                          required
                        />
                        <label htmlFor="fechaNacimento">
                          Fecha de Nacimiento
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row g-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="sexo"
                          name="sexo"
                          onChange={onChangeForm}
                          aria-label="Sexo"
                        >
                          <option selected disabled value>
                            Seleccionar
                          </option>
                          <option value="Hombre">Hombre</option>
                          <option value="Mujer">Mujer</option>&gt;
                        </select>
                        <label htmlFor="sexo">Sexo</label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="situacionLaboral"
                          name="situacionLaboral"
                          aria-label="Situación Laboral"
                          onChange={onChangeForm}
                        >
                          <option selected disabled value>
                            Seleccionar
                          </option>
                          <option value="Empleado">Empleado</option>
                          <option value="Empleado Publico">
                            Empleado Público
                          </option>
                          <option value="Trabajador">
                            Trabajador Independiente
                          </option>
                          <option value="Jubilado">Jubilado</option>
                          <option value="Autonomo">Autonomo</option>
                          <option value="Benificiario">
                            Benificiario de Plan
                          </option>
                          <option value="Desempleado">Desempleado</option>
                          <option value="Otro">Otro</option>
                        </select>
                        <label htmlFor="situacionLaboral">
                          Situación Laboral
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row g-2">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="banco"
                        name="banco"
                        onChange={onChangeForm}
                        aria-label
                      >
                        <option selected disabled value>
                          Seleccionar
                        </option>
                        <option value="Banco Galicia">Banco Galicia</option>
                        <option value="Banco Macro">Banco Macro</option>
                        <option value="Banco Nacion">Banco Nacion</option>
                        <option value="Banco HSBC">Banco HSBC</option>
                        <option value="Banco Supervielle">
                          Banco Supervielle
                        </option>
                        <option value="Banco Provincia de Buenos Aires">
                          Banco Provincia de Buenos Aires
                        </option>
                        <option value="Banco Patagonia">Banco Patagonia</option>
                        <option value="Banco ICBC">Banco ICBC</option>
                        <option value="Banco BBVA">Banco BBVA</option>
                        <option value="Banco de Cordoba">
                          Banco de Cordoba{" "}
                        </option>
                        <option value="Banco de la Ciudad de Buenos Aires">
                          Banco de la Ciudad de Buenos Aires
                        </option>
                        <option value="Banco Hipotecario">
                          Banco Hipotecario
                        </option>
                        <option value="Banco de San Juan">
                          Banco de San Juan{" "}
                        </option>
                        <option value="Banco de Tucuman ">
                          Banco de Tucuman{" "}
                        </option>
                        <option value="Banco de Rosario ">
                          Banco de Rosario{" "}
                        </option>
                        <option value="Banco Santander Rio">
                          Banco Santander Rio
                        </option>
                        <option value="Banco del Chubut">
                          Banco del Chubut{" "}
                        </option>
                        <option value="Banco Santa Cruz">
                          Banco Santa Cruz{" "}
                        </option>
                        <option value="Banco de La Pampa">
                          Banco de La Pampa{" "}
                        </option>
                        <option value="Banco de Corrientes">
                          Banco de Corrientes
                        </option>
                        <option value="Banco Regional de Cuyo">
                          Banco Regional de Cuyo
                        </option>
                        <option value="Banco Provincia de Neuquen">
                          Banco Provincia de Neuquen{" "}
                        </option>
                        <option value="Banco Brubank">Banco Brubank</option>
                        <option value="Banco Interfinanzas">
                          Banco Interfinanzas{" "}
                        </option>
                        <option value="Banco CrediCoop">Banco CrediCoop</option>
                        <option value="Banco de Valores">
                          Banco de Valores
                        </option>
                        <option value="Banco ROELA">Banco ROELA </option>
                        <option value="Banco MARIVA">Banco MARIVA </option>
                        <option value="Banco Itau">Banco Itau</option>
                        <option value="Banco Provincia de Tierra del Fuego">
                          Banco Provincia de Tierra del Fuego{" "}
                        </option>
                        <option value="Banco ENZ">Banco ENZ </option>
                        <option value="Banco MERIDIAN">Banco MERIDIAN</option>
                        <option value="Banco Comafi">Banco Comafi</option>
                        <option value="Banco DE INVERSION Y COMERCIO EXTERIOR S">
                          Banco DE INVERSION Y COMERCIO EXTERIOR
                        </option>
                        <option value="Banco Piano">Banco Piano</option>
                        <option value="Banco Julio">Banco Julio</option>
                        <option value="Banco Rioja">Banco Rioja</option>
                        <option value="Banco DEL SOL">Banco DEL SOL</option>
                        <option value="Banco Nuevo del Chaco">
                          Banco Nuevo del Chaco
                        </option>
                        <option value="Banco VOII">Banco VOII</option>
                        <option value="Banco de Formosa">
                          Banco de Formosa
                        </option>
                        <option value="Banco CMF">Banco CMF</option>
                        <option value="Banco de Santiago del Estero">
                          Banco de Santiago del Estero
                        </option>
                        <option value="Banco Industrial">
                          Banco Industrial{" "}
                        </option>
                        <option value="Banco Nuevo de Santa Fe">
                          Banco Nuevo de Santa Fe{" "}
                        </option>
                        <option value="Banco CETELEM ARGENTINA">
                          Banco CETELEM ARGENTINA
                        </option>
                        <option value="Banco DE SERVICIOS FINANCIEROS">
                          Banco DE SERVICIOS FINANCIEROS
                        </option>
                        <option value="Banco BRADESCO ARGENTINA U">
                          Banco BRADESCO ARGENTINA U
                        </option>
                        <option value="Banco DE SERVICIOS Y TRANCCIONES">
                          Banco DE SERVICIOS Y TRANCCIONES
                        </option>
                        <option value="MASVENTAS">MASVENTAS</option>
                        <option value="Wilobank">Wilobank</option>
                        <option value="Uala">Uala</option>
                        <option value="Nuevo de Entre Rios">
                          Nuevo de Entre Rios
                        </option>
                        <option value="COLUMBIA">COLUMBIA</option>
                        <option value="BICA">BICA</option>
                        <option value="COINAG">COINAG</option>
                        <option value="de Comercio">de Comercio</option>
                        <option value="Otro">Otro</option>
                      </select>
                      <label htmlFor="banco">Banco</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row g-2">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="Telefono"
                        name="telefono"
                        placeholder="Teléfono"
                        pattern="[0-9]+"
                        minLength={6}
                        maxLength={15}
                        onChange={onChangeForm}
                        required
                      />
                      <label htmlFor="Telefono">Teléfono</label>
                      <p
                        className="text-center"
                        style={{ marginBottom: 0, fontSize: "14px !important" }}
                      >
                        Completá tu celular con código de area sin 0 ni 15
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row g-2">
                    <div className="form-floating">
                      <input
                        type="mail"
                        className="form-control"
                        name="mail"
                        id="mail"
                        placeholder="Correo electrónico"
                        onChange={onChangeForm}
                        required
                      />
                      <label htmlFor="mail">Correo electrónico</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row g-2">
                    <button
                      className="btn app-button btn-block text-white"
                      type="button"
                      onClick={()=> activar &&  enviar()}
                    >
                      Solicitar
                    </button>
                  </div>
                </div>
                <div className="mb-3 form-check pt-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="terminoCondiciones"
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="terminoCondiciones"
                    style={{
                      fontSize: "14px !important",
                      color: "#000 !important",
                    }}
                  >
                    <a
                      className="coa"
                      href="#"
                      style={{ color: "#000" }}
                      target="_blank"
                    >
                      {" "}
                      Términos y Condiciones
                    </a>
                    .
                  </label>
                </div>
              </form>
            </div>
          </div>

          <div className="d-md-none pt-5">
            <br />
          </div>
        </div>
          :
        <div className="container">
                    <div className="row pt-2">
            <p className="titulo-1 text-center">

                  Enviamos tus datos en breve un representate se contactara contigo</p>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default SolicitudPrestamo;
