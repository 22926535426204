
import './Planes.css';
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { url } from '../../service/EndPoint';




function Planes(props) {

  const [plan, setPlan] = useState(null);
  
  const [montoDesde, setMontoDesde] = useState(0);
  const [montoHasta, setMontoHasta] = useState(0);
  const [cuotasDesde, setCuotasDesde] = useState(0);
  const [cuotasHasta, setCuotasHasta] = useState(0);
  const [primerVencimiento, setPrimerVencimiento] = useState("");
  const [stepMonto, setStepMonto] = useState(0)
  const [stepCuota, setStepCuota] = useState(0)
  const [amount, setAmount] = useState(5000);
  const [days, setDays] = useState(3);
  const [valorCuota, setValorCuota] = useState("");
  const [tem, setTem] = useState(0);
  const [estado, setStado]= useState()

  function getData(){
    axios.post(url+'simulador/list.php')
    .then((getData) => {
      setMontoDesde(getData.data[0].montoDesde);
      setMontoHasta(getData.data[0].montoHasta);
      setCuotasDesde(getData.data[0].cuotasDesde);
      setCuotasHasta(getData.data[0].cuotasHasta);
      setStepMonto(getData.data[0].stepMonto);
      setStepCuota(getData.data[0].stepCuota);
      setTem(Number(getData.data[0].tem));

    });
  }

  useEffect(() => {  
    getData()
  }, []);

  useEffect(() => {
  
    simular(plan?.id, amount, days);
  }, [plan?.id, amount, days]);




  const simular = (planId, monto, cuotas) => {
    let a = tem * Math.pow(1 + tem, days);
    let b = Math.pow(1 + tem, days) - 1;
    let c = monto * (a / b);
    setValorCuota(Math.round(c));
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleDaysChange = (event) => {
    setDays(event.target.value);
  };



  function enviar(){
	const data = {
		montoDesde: montoDesde,
		montoHasta: montoHasta,
		cuotasDesde: cuotasDesde,
		cuotasHasta: cuotasHasta,
		stepCuota: stepCuota,
		stepMonto: stepMonto,
    estado: estado,
		tem: tem,
    id: 1
	}
    axios.post(url+`simulador/update.php`, data)
    .then((res) => {
          let {data} = res;                             
          if(data.success && data.message){              
              if(data.success === 1){ 
                 window.alert('Registro actualizado')
              }
          }
        })
        .catch(function (res) {
          
        });  
      
  }


  return (
			<div className='container'>
				  <div className="text-start">
        <NavLink exact to={"/dashboard"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
        <div
          className="card shadow mb-3"
          style={{ background: "#292740", border: "0px" }}
        >
          <div className="card-body">
            <div className="text-center text-white">Simulador</div>
          </div>
        </div>
            <div className="row">
				<div className='col-md-6 cols-sm-12 pt-5'>
				<div className="row">
                  <div className="row g-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="minimo"
                          name="montoDesde"
                          placeholder="Monto Minimo"
						              value={montoDesde && montoDesde}
                          onChange={(e)=>setMontoDesde(e.target.value)}                
                         
                        />
                        <label htmlFor="Nombre">Monto Minimo</label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="maximoo"
                          name="montoHasta"
                          placeholder="Monto Maximo"
						  value={montoHasta && montoHasta}
                          onChange={(e)=>setMontoHasta(e.target.value)}
                         
                        />
                        <label htmlFor="Apellido">Monto Maximo</label>
                      </div>
                    </div>
					<div className="col-md">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="stepMonto"
                          name="stepMonto"
                          placeholder="Step Monto"
						  value={stepMonto && stepMonto}
                          onChange={(e)=>setStepMonto(e.target.value)}
                         
                        />
                        <label htmlFor="stepMonto">Step Monto</label>
                      </div>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="row g-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="cuotasDesde"
                          name="cuotasDesde"
                          placeholder="Cuota Minima"
						  value={cuotasDesde && cuotasDesde}
                          onChange={(e)=>setCuotasDesde(e.target.value)}                
                         
                        />
                        <label htmlFor="tem">Cuota Minima</label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="cuotasHasta"
                          name="cuotasHasta"
                          placeholder="Cuota Maximo"
						  value={cuotasHasta && cuotasHasta}
                          onChange={(e)=>setCuotasHasta(e.target.value)}
                         
                        />
                        <label htmlFor="Apellido">Cuota Maximo</label>
                      </div>
                    </div>
					<div className="col-md">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="stepCuota"
                          name="stepCuota"
                          placeholder="Step Cuota"
						  value={stepCuota && stepCuota}
                          onChange={(e)=>setStepCuota(e.target.value)}
                         
                        />
                        <label htmlFor="Apellido">Step cuota</label>
                      </div>
                    </div>
                  </div>
			
                  <div className="row g-2">
                    <div className="col-4">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="TEM"
                          name="TEM"
                          placeholder="TEM"
						  value={tem && tem}
                          onChange={(e)=>setTem(e.target.value)}                
                         
                        />
                        <label htmlFor="tem">TEM</label>
                      </div>
                    </div>
				
                </div>
				<div className="row g-2">
                    <button
                      className="btn app-button btn-block text-white"
                      type="button"
                     onClick={()=> enviar()}
                    >
                      Guardar
                    </button>
                  </div>
				</div>
				</div>
              <div className="col-md-6 cols-sm-12 pt-5">
                <div className="card " style={{border: '0px'}}>
                  <div className="card-body">
                    <div className="container">
                      <h2 className="card-title">
                        <strong />
                      </h2>
                      <div className="row g-3">
                        <div className="col-4">
                          <label>Pedís</label>
                          <input
                            type="text"
                            className="form-control res"
                            id="amount"
                            value={Number(amount).toLocaleString('es-ES')}
                            onChange={handleAmountChange}
                            readOnly
                          />
                        </div>
                        <div className="col-4">
                          <label>Cuotas</label>
                          <input
                            type="text"
                            className="form-control res"
                            id="days"
                            value={days}
                            onChange={handleDaysChange}
                            readOnly
                          />
                        </div>
                        <div className="col-4">
                          <label>
                            Valor Cuota
                          </label>
                          <input
                            type="text"
                            id="valorcuota"
                            className="form-control res"
                            value={valorCuota ? Number(valorCuota).toLocaleString('es-ES') : '-'}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container pt-2">
                      <div className="pt-1">
                        <label htmlFor="customRange3" className="form-label">
                          <h4>
                            Monto desde{' '}
                            <strong>
                              <span id="monto_desde">{Number(montoDesde).toLocaleString('es-ES')}</span>
                            </strong>{" "}
                            a{' '}
                            <strong>
                              <span id="monto_hasta">{Number(montoHasta).toLocaleString('es-ES')}</span>
                            </strong>
                          </h4>
                        </label>
                        <input
                          type="range"
                          className="form-range"
                          min={montoDesde}
                          max={montoHasta}
                          step={stepMonto}
                          id="customRange3"
                          value={amount && amount}
                          onChange={handleAmountChange}
                        />
                      </div>
                      <div className="pt-2">
                        <label htmlFor="customRange4" className="form-label">
                          <h4>
                            De{" "}
                            <strong>
                              <span id="cuotas_desde">{cuotasDesde}</span>
                            </strong>{" "}
                            a
                            <strong>{' '}
                              <span id="cuotas_hasta">{cuotasHasta}</span>
                            </strong>{" "}
                            cuotas
                          </h4>
                        </label>
                        <input
                          type="range"
                          className="form-range"
                          min={cuotasDesde}
                          max={cuotasHasta}
                          step={stepCuota}
                          id="customRange4"
                          value={days}
                          onChange={handleDaysChange}
                        />
                      </div>
                      
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
			</div>
        
  );
}

export default Planes;