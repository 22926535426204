import "./SolicitudAlta.css";
import { NavLink } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { url } from "../../service/EndPoint";
import logo from "../../assets/image/logo_cabecera.png";
import axios from "axios";
import { useState } from "react";
import img1 from "../../assets/image/mano_card.jpg"

const SolicitudAlta = ({}) => {
  const [exito, setExito] = useState(true);
  const [solicitud, setSolicitud] = useState({
    nombre: "",
    apellido: "",
    dni: "",
    fechaNacimiento: "",
    telefono: "",
    mail: "",
    direccion: "",
    localidad:'',
  });

  const onChangeForm = (e) => {
    setSolicitud({ ...solicitud, [e.target.name]: e.target.value });
  };
  const [activar, setActivar] = useState(true);

  function enviar() {
    setActivar(false);
    if (
      solicitud.nombre === "" ||
      solicitud.apellido === "" ||
      solicitud.dni === "" ||
      solicitud.telefono === "" ||
      solicitud.mail === ""
    ) {
      setActivar(true);
      alert("Por favor, complete todos los campos.");
    } else {
      axios
        .post(url + `solicitudes/altaSocio.php`, solicitud)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {});
    }
  }

  return (
    <div className="solicitudprestamo">
      <div className="container">
        <header id="load">
          <div id="layoutDefault">
            <div id="layoutDefault_content">
              <nav
                className="navbar navbar-expand-lg navbar-dark  fixed-top d-none d-sm-none d-md-block"
                style={{ background: "#292740", color: "#fff !important" }}
              >
                <div className="container-fluid">
                  <a
                    className="navbar-brand d-none d-sm-none d-md-block"
                    href="#"
                  >
                    <Image src={logo} width="180px" />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarNav"
                  >
                    <div className="menu ">
                      <div className="menu menu-titulo">
                        <NavLink exact to="/">
                          <div className="nenu-item">INICIO</div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div className="d-none d-md-block d-sm-none">
          <br />
          <br />
          <br />
        </div>
        {exito ? (
          <div className="container">
            <div className="row pt-2">
              <p className="titulo-1 text-center">
              Alta de Socio
              </p>
              <p className=" text-center" style={{color: '#3189af'}}>Si quieres asociarte, por favor llena este formulario.</p>
              <div className="col-md-4 col-sm-12 pt-3">
                  <img  src={img1} className="img-fluid"/>
              </div>
              <div className="col-md-7 col-sm-12 mx-auto">
                <form
                  id="solicitudPrestamo"
                  className="needs-validation"
                  noValidate
                  method="post"
                >
                  <div className="row">
                    <div className="row g-2">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="Nombre"
                            name="nombre"
                            placeholder="Nombre"
                            onChange={onChangeForm}
                            required
                          />
                          <label htmlFor="Nombre">Nombre</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="Apellido"
                            name="apellido"
                            placeholder="Apellido"
                            onChange={onChangeForm}
                            required
                          />
                          <label htmlFor="Apellido">Apellido</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row g-2">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="dni"
                            name="dni"
                            placeholder="Nº Documneto de Identidad"
                            pattern="[0-9]+"
                            minLength={6}
                            maxLength={10}
                            onChange={onChangeForm}
                            required
                          />
                          <label htmlFor="dni">Nº Documento de Identidad</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="date"
                            className="form-control"
                            id="fechaNacimiento"
                            name="fechaNacimiento"
                            placeholder="Fecha de Nacimiento"
                            onChange={onChangeForm}
                            required
                          />
                          <label htmlFor="fechaNacimento">
                            Fecha de Nacimiento
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row g-2">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="Direccion"
                            name="direccion"
                            placeholder="Dirección"
                            onChange={onChangeForm}
                            required
                          />
                          <label htmlFor="Direccion">Dirección</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="Localidad"
                            name="localidad"
                            placeholder="Localidad"
                            onChange={onChangeForm}
                            required
                          />
                          <label htmlFor="Localidadn">Localidad</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row g-2">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="Telefono"
                          name="telefono"
                          placeholder="Teléfono"
                          pattern="[0-9]+"
                          minLength={6}
                          maxLength={15}
                          onChange={onChangeForm}
                          required
                        />
                        <label htmlFor="Telefono">Teléfono</label>
                        <p
                          className="text-center"
                          style={{
                            marginBottom: 0,
                            fontSize: "14px !important",
                          }}
                        >
                          Completá tu celular con código de area sin 0 ni 15
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row g-2">
                      <div className="form-floating">
                        <input
                          type="mail"
                          className="form-control"
                          name="mail"
                          id="mail"
                          placeholder="Correo electrónico"
                          onChange={onChangeForm}
                          required
                        />
                        <label htmlFor="mail">Correo electrónico</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row g-2">
                      <button
                        className="btn app-button btn-block text-white"
                        type="button"
                        onClick={() => activar && enviar()}
                      >
                        Solicitar
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>

            <div className="d-md-none pt-5">
              <br />
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row pt-2">
              <p className="titulo-1 text-center">
                Enviamos tus datos en breve un representate se contactara
                contigo
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SolicitudAlta;
