import React from 'react';
import { NavLink } from 'react-router-dom';
import "./Dashboard.css"

function Dashboard() {
  return (
	<div className='dashboard'>
		<div className='container pt-3'>
			<div className='card shadow mb-3' style={{ background: "#292740",border:'0px' }}>
				<div className='card-body'>
				<div className='text-center text-white'>
					Panel de administración
				</div>
				</div>  
			</div>
			<div className="row pt-5">
				<div className='col-md-6 col-sm-12  mb-3'>
					<NavLink exact to={'/Plan-ayuda-economica'} >
						<div className='card shadow'>
							<div className="contend-card" >
								<div className='container'>
									<div className='text-start'>                      
										<span style={{color:'#474747'}}>Plan ayuda economica</span>
									</div>                  
								</div>                                     
							</div>
						</div>
					</NavLink>            
				</div>
				<div className='col-md-6 col-sm-12  mb-3'>
					<NavLink exact to={'/servicios-categoria'} >
						<div className='card shadow'>
							<div className="contend-card" >
								<div className='container'>
									<div className='text-start'>                      
										<span style={{color:'#474747'}}>Servicios</span>
									</div>                  
								</div>                                     
							</div>
						</div>
					</NavLink>            
				</div>
				<div className='col-md-6 col-sm-12  mb-3'>
					<NavLink exact to={'/convenios-categoria/'} >
						<div className='card shadow'>
							<div className="contend-card" >
								<div className='container'>
									<div className='text-start'>                      
										<span style={{color:'#474747'}}>Convenios</span>
									</div>                  
								</div>                                     
							</div>
						</div>
					</NavLink>            
				</div>
				<div className='col-md-6 col-sm-12  mb-3'>
					<NavLink exact to={'/preguntas'} >
						<div className='card shadow'>
							<div className="contend-card" >
								<div className='container'>
									<div className='text-start'>                      
										<span style={{color:'#474747'}}>Preguntas Frecuentes</span>
									</div>                  
								</div>                                     
							</div>
						</div>
					</NavLink>            
				</div>
				<div className='col-md-6 col-sm-12  mb-3'>
					<NavLink exact to={'/notificaciones'} >
						<div className='card shadow'>
							<div className="contend-card" >
								<div className='container'>
									<div className='text-start'>                      
										<span style={{color:'#474747'}}>Notificaciones</span>
									</div>                  
								</div>                                     
							</div>
						</div>
					</NavLink>            
				</div>
				<div className='col-md-6 col-sm-12  mb-3'>
					<NavLink exact to={'/identidad-sitio'} >
						<div className='card shadow'>
							<div className="contend-card" >
								<div className='container'>
									<div className='text-start'>                      
										<span style={{color:'#474747'}}>Identidad Sitio</span>
									</div>                  
								</div>                                     
							</div>
						</div>
					</NavLink>            
				</div>
				<div className='col-md-6 col-sm-12  mb-3'>
					<NavLink exact to={'/usuarios'} >
						<div className='card shadow'>
							<div className="contend-card" >
								<div className='container'>
									<div className='text-start'>                      
										<span style={{color:'#474747'}}>Usuarios</span>
									</div>                  
								</div>                                     
							</div>
						</div>
					</NavLink>            
				</div>
			</div>	  
		</div>
	</div>
  )
}

export default Dashboard
