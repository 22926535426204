import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";
import DashNavFooter from "../components/DashNavFooter";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { rootState } = useAuth();
  const { isAuth, showLogin } = rootState;
  const location = useLocation();
  const auth = useAuth();

  if (isAuth) {
    return (
      <Route {...rest}>
        <DashNavFooter auth={auth} />
        <Component />
      </Route>
    );
  } else if (showLogin) {
    return <Redirect to={{ pathname: "/", state: { from: location } }} />;
  }
}
