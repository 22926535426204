import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useState } from "react";
import { url } from "../../service/EndPoint";

function DetalleModalPrestamo({ show, onHide, data, actualizar }) {
  function formatearFecha(fecha) {
    if (!(fecha instanceof Date)) {
      fecha = new Date(fecha);
    }

    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    const diaFormateado = dia.toString().padStart(2, "0");
    const mesFormateado = mes.toString().padStart(2, "0");

    const fechaFormateada = `${diaFormateado}/${mesFormateado}/${año}`;

    return fechaFormateada;
  }
  useEffect(() => {
    setSolicitud(data);
  }, [data]);

  const [solicitud, setSolicitud] = useState("");

  const onChangeForm = (e) => {
    setSolicitud({ ...solicitud, [e.target.name]: e.target.value });
  };
  const [activar, setActivar] = useState(true);

  function enviar() {
    axios
      .post(url + `solicitudes/editarSolicitudP.php`, solicitud)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          if (data.success === 1) {
            actualizar();
            onHide()
          }
        }
      })
      .catch(function (res) {});
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>Fecha solicitud {data && formatearFecha(data.create_at)}</div>
        </div>
        <form
          id="solicitudPrestamo"
          className="needs-validation"
          noValidate
          method="post"
        >
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="number"
                    id="monto"
                    name="monto"
                    className="form-control currency text-center"
                    placeholder="Monto"
                    value={solicitud && solicitud.monto}
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="monto">Monto</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="Nombre"
                    name="nombre"
                    value={solicitud && solicitud.nombre}
                    placeholder="Nombre"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="Nombre">Nombre</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="Apellido"
                    name="apellido"
                    value={solicitud && solicitud.apellido}
                    placeholder="Apellido"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="Apellido">Apellido</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="dni"
                    name="dni"
                    value={solicitud && solicitud.dni}
                    placeholder="Nº Documneto de Identidad"
                    pattern="[0-9]+"
                    minLength={6}
                    maxLength={10}
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="dni">Nº Documento de Identidad</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="date"
                    className="form-control"
                    id="fechaNacimiento"
                    name="fechaNacimiento"
                    value={solicitud && solicitud.fechaNacimiento}
                    placeholder="Fecha de Nacimiento"
                    onChange={onChangeForm}
                    required
                  />
                  <label htmlFor="fechaNacimento">Fecha de Nacimiento</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="sexo"
                    name="sexo"
                    value={solicitud && solicitud.sexo}
                    onChange={onChangeForm}
                    aria-label="Sexo"
                  >
                    <option selected disabled value>
                      Seleccionar
                    </option>
                    <option value="Hombre">Hombre</option>
                    <option value="Mujer">Mujer</option>&gt;
                  </select>
                  <label htmlFor="sexo">Sexo</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="situacionLaboral"
                    name="situacionLaboral"
                    value={solicitud && solicitud.situacionLaboral}
                    aria-label="Situación Laboral"
                    onChange={onChangeForm}
                  >
                    <option selected disabled value>
                      Seleccionar
                    </option>
                    <option value="Empleado">Empleado</option>
                    <option value="Empleado Publico">Empleado Público</option>
                    <option value="Trabajador">Trabajador Independiente</option>
                    <option value="Jubilado">Jubilado</option>
                    <option value="Autonomo">Autonomo</option>
                    <option value="Benificiario">Benificiario de Plan</option>
                    <option value="Desempleado">Desempleado</option>
                    <option value="Otro">Otro</option>
                  </select>
                  <label htmlFor="situacionLaboral">Situación Laboral</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="banco"
                  name="banco"
                  value={solicitud && solicitud.banco}
                  onChange={onChangeForm}
                  aria-label
                >
                  <option selected disabled value>
                    Seleccionar
                  </option>
                  <option value="Banco Galicia">Banco Galicia</option>
                  <option value="Banco Macro">Banco Macro</option>
                  <option value="Banco Nacion">Banco Nacion</option>
                  <option value="Banco HSBC">Banco HSBC</option>
                  <option value="Banco Supervielle">Banco Supervielle</option>
                  <option value="Banco Provincia de Buenos Aires">
                    Banco Provincia de Buenos Aires
                  </option>
                  <option value="Banco Patagonia">Banco Patagonia</option>
                  <option value="Banco ICBC">Banco ICBC</option>
                  <option value="Banco BBVA">Banco BBVA</option>
                  <option value="Banco de Cordoba">Banco de Cordoba </option>
                  <option value="Banco de la Ciudad de Buenos Aires">
                    Banco de la Ciudad de Buenos Aires
                  </option>
                  <option value="Banco Hipotecario">Banco Hipotecario</option>
                  <option value="Banco de San Juan">Banco de San Juan </option>
                  <option value="Banco de Tucuman ">Banco de Tucuman </option>
                  <option value="Banco de Rosario ">Banco de Rosario </option>
                  <option value="Banco Santander Rio">
                    Banco Santander Rio
                  </option>
                  <option value="Banco del Chubut">Banco del Chubut </option>
                  <option value="Banco Santa Cruz">Banco Santa Cruz </option>
                  <option value="Banco de La Pampa">Banco de La Pampa </option>
                  <option value="Banco de Corrientes">
                    Banco de Corrientes
                  </option>
                  <option value="Banco Regional de Cuyo">
                    Banco Regional de Cuyo
                  </option>
                  <option value="Banco Provincia de Neuquen">
                    Banco Provincia de Neuquen{" "}
                  </option>
                  <option value="Banco Brubank">Banco Brubank</option>
                  <option value="Banco Interfinanzas">
                    Banco Interfinanzas{" "}
                  </option>
                  <option value="Banco CrediCoop">Banco CrediCoop</option>
                  <option value="Banco de Valores">Banco de Valores</option>
                  <option value="Banco ROELA">Banco ROELA </option>
                  <option value="Banco MARIVA">Banco MARIVA </option>
                  <option value="Banco Itau">Banco Itau</option>
                  <option value="Banco Provincia de Tierra del Fuego">
                    Banco Provincia de Tierra del Fuego{" "}
                  </option>
                  <option value="Banco ENZ">Banco ENZ </option>
                  <option value="Banco MERIDIAN">Banco MERIDIAN</option>
                  <option value="Banco Comafi">Banco Comafi</option>
                  <option value="Banco DE INVERSION Y COMERCIO EXTERIOR S">
                    Banco DE INVERSION Y COMERCIO EXTERIOR
                  </option>
                  <option value="Banco Piano">Banco Piano</option>
                  <option value="Banco Julio">Banco Julio</option>
                  <option value="Banco Rioja">Banco Rioja</option>
                  <option value="Banco DEL SOL">Banco DEL SOL</option>
                  <option value="Banco Nuevo del Chaco">
                    Banco Nuevo del Chaco
                  </option>
                  <option value="Banco VOII">Banco VOII</option>
                  <option value="Banco de Formosa">Banco de Formosa</option>
                  <option value="Banco CMF">Banco CMF</option>
                  <option value="Banco de Santiago del Estero">
                    Banco de Santiago del Estero
                  </option>
                  <option value="Banco Industrial">Banco Industrial </option>
                  <option value="Banco Nuevo de Santa Fe">
                    Banco Nuevo de Santa Fe{" "}
                  </option>
                  <option value="Banco CETELEM ARGENTINA">
                    Banco CETELEM ARGENTINA
                  </option>
                  <option value="Banco DE SERVICIOS FINANCIEROS">
                    Banco DE SERVICIOS FINANCIEROS
                  </option>
                  <option value="Banco BRADESCO ARGENTINA U">
                    Banco BRADESCO ARGENTINA U
                  </option>
                  <option value="Banco DE SERVICIOS Y TRANCCIONES">
                    Banco DE SERVICIOS Y TRANCCIONES
                  </option>
                  <option value="MASVENTAS">MASVENTAS</option>
                  <option value="Wilobank">Wilobank</option>
                  <option value="Uala">Uala</option>
                  <option value="Nuevo de Entre Rios">
                    Nuevo de Entre Rios
                  </option>
                  <option value="COLUMBIA">COLUMBIA</option>
                  <option value="BICA">BICA</option>
                  <option value="COINAG">COINAG</option>
                  <option value="de Comercio">de Comercio</option>
                  <option value="Otro">Otro</option>
                </select>
                <label htmlFor="banco">Banco</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="Telefono"
                  name="telefono"
                  value={solicitud && solicitud.telefono}
                  placeholder="Teléfono"
                  pattern="[0-9]+"
                  minLength={6}
                  maxLength={15}
                  onChange={onChangeForm}
                  required
                />
                <label htmlFor="Telefono">Teléfono</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <input
                  type="mail"
                  className="form-control"
                  name="mail"
                  value={solicitud && solicitud.mail}
                  id="mail"
                  placeholder="Correo electrónico"
                  onChange={onChangeForm}
                  required
                />
                <label htmlFor="mail">Correo electrónico</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Estado"
                  name="estado"
                  onChange={onChangeForm}
                >
                  <option selected>
                    {Number(solicitud && solicitud.estado) === 0 && "Nuevo"}
                    {Number(solicitud && solicitud.estado) === 1 &&
                      "Pendiente"}{" "}
                    {Number(solicitud && solicitud.estado) === 2 && "Cerrada"}
                  </option>
                  <option value={0}>Nuevo</option>
                  <option value={1}>Pendiente</option>
                  <option value={2}>Cerrado</option>
                </select>
                <label htmlFor="floatingSelect">Estado</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <button
                className="btn app-button btn-block text-white"
                type="button"
                onClick={() => activar && enviar()}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DetalleModalPrestamo;
