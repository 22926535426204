import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/image/logo_cabecera.png";
import Image from "react-bootstrap/Image";
import { url } from "../../service/EndPoint";
import axios from "axios";
import "./MasInformacion.css";

const MasInformacion = ({}) => {
  const [solicitud, setSolicitud] = useState({
    nombre: "",
    mail: "",
    consulta: "",
  });
  const onChangeValue = (e) => {
    setSolicitud({
      ...solicitud,
      [e.target.name]: e.target.value,
    });
  };
  const [activar, setActivar] = useState(true);
  const [exito, setExito] = useState(true);

  function validarCorreoElectronico(correo) {
    var expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(correo);
  }

  function enviar() {
    setActivar(false);
    if (
      solicitud.nombre === "" ||
      solicitud.consulta === "" ||
      solicitud.mail === "" ||
      !validarCorreoElectronico(solicitud.mail)
    ) {
      setActivar(true);
      alert(
        "Por favor, debes completar los campos y ingresar un correo valido"
      );
    } else {
      axios
        .post(url + `solicitudes/consulta.php`, solicitud)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {});
    }
  }
  return (
    <div className="masinformacion">
      <header id="load">
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <nav
              className="navbar navbar-expand-lg navbar-dark  fixed-top"
              style={{ background: "#292740", color: "#fff !important" }}
            >
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <Image src={logo} width="180px" alt="AMUAM" />
                </a>

                <div className="menu ">
                  <div className="menu menu-titulo">
                    <NavLink exact to={"/home"}>
                      <div className="nenu-item">VOLVER</div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="container pt-5">
        {exito ? (
          <form>
            <div className="pt-5">
			<div className="titulo-1 ">Envianos tu consulta</div>

            </div>
			<div className="row">
              <div className="row g-2">
                <div className="form-floating">
				<input
                      type="text"
                      className="form-control"
                      name="nombre"
                      placeholder="NOMBRE"
                      onChange={onChangeValue}
                    />
                    <label htmlFor="Nombre">NOMBRE</label>
                    <div className="invalid-feedback">
                      Por favor completa este campo
                    </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row g-2">
                <div className="form-floating">
                  <input
                    type="mail"
                    className="form-control"
                    name="mail"
                    placeholder="E.MAIL"
                    onChange={onChangeValue}
                  />
                  <label htmlFor="mail">E.MAIL</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row g-2">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    name="consulta"
                    onChange={onChangeValue}
                    id="floatingTextarea"
                    defaultValue={""}
                    style={{ height: "100px" }}
                  />
                  <label htmlFor="floatingTextarea">Consulta</label>
                </div>
              </div>
            </div>
            <div className="text-center pt-3">
              <button
                className="btn app-button text-white"
                type="button"
                onClick={() => activar && enviar()}
              >
                Enviar
              </button>
            </div>
          </form>
        ) : (
          <div className="text-center pt-5"
		  >
			<div className="pt-5 titulo-1">Enviamos tu consulta !</div></div>
        )}
      </div>
    </div>
  );
};

export default MasInformacion;
