import { Redirect } from "react-router-dom";
import useAuth from "../auth/useAuth";

export default function PublicRoute({ component: Component, ...rest }) {
  const { rootState } = useAuth();
  const { isAuth, showLogin } = rootState;

  if (isAuth) {
    return (
         <Redirect to="/dashboard" />    
    );
  } else if (showLogin) {
    return <Component />;
  }
}
