import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { url } from "../../service/EndPoint"; 
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import cam from "./utils/camara.png";
import Cropper from "react-easy-crop";
import Slider from '@mui/material/Slider'
import { dataURLtoFile } from "./utils/dataURLtoFile";
import "./styles.css"
import {
  Modal,
} from "react-bootstrap";

import "./userProfile.css";


export default function UsersProfile() {



  const [APIData, setAPIData] = useState([]);
  const [filtro,setFiltro] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false); 
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false); 
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false); 
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false); 
  const [nameUser, setNameUser] = useState('');
  const [message, setMessage]=useState(null);
  const [lastnameUser, setLastname] = useState('');
  const [phoneUser, setPhone] = useState('');
  const [emailUser, setEmail] = useState('');
  const [passwordUser, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const [selectedOption, setSelectedOption] = useState('');


  const inputRef1 = useRef('');   
  const triggerFileSelectPopup1 = () => inputRef1.current.click();
  const [image1, setImage1] = useState(null);
  const [file1,setFile1]= useState(null);
  const [preview1, setPreview1] = useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [datoSeleccionado,setDatoSeleccionado] = useState({
          id: '',
          name: '',
          last_name: '',
          phone: '',
          email: '',
        
          
                            
  });





  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };


 const onDownload = () => {
    generateDownload(image1, croppedArea);
 };






const createImage = (url) =>
 new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
 });

function getRadianAngle(degreeValue) {
 return (degreeValue * Math.PI) / 180;
}

const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0)=> {
 const image = await createImage(imageSrc);
 const canvas = document.createElement("canvas");
 const ctx = canvas.getContext("2d");

 const maxSize = Math.max(image.width, image.height);
 const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

 // set each dimensions to double largest dimension to allow for a safe area for the
 // image to rotate in without being clipped by canvas context
 canvas.width = safeArea;
 canvas.height = safeArea;

 // translate canvas context to a central location on image to allow rotating around the center.
 ctx.translate(safeArea / 2, safeArea / 2);
 ctx.rotate(getRadianAngle(rotation));
 ctx.translate(-safeArea / 2, -safeArea / 2);

 // draw rotated image and store data.
 ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
 );

 const data = ctx.getImageData(0, 0, safeArea, safeArea);

 // set canvas width to final desired crop size - this will clear existing context
 canvas.width = pixelCrop.width;
 canvas.height = pixelCrop.height;

 // paste generated rotate image with correct offsets for x,y crop values.
 ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
 );

 // As Base64 string
 // return canvas.toDataURL("image/jpeg");
 return canvas;
}



 const generateDownload = async (imageSrc, crop) => {
 if (!crop || !imageSrc) {
    return;
    }
    const canvas = await getCroppedImg(imageSrc, crop);
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedUrlToFile = dataURLtoFile(
          canvasDataUrl,
          "cropped-image.jpeg"
       );
    setFile1(convertedUrlToFile);      
    const reader = new FileReader();
       reader.readAsDataURL(convertedUrlToFile);
       reader.addEventListener("load", () => {
          setPreview1(reader.result);
          setShow1(false);            
       });
          
 };


 useEffect(() => {

      axios.get(url+'profile/list/')
      .then((response) => {                
              setAPIData(response.data);                  
          })

        
      },[]);

  const getData = () => {
      axios.get(url+'profile/list/')
      .then((getData) => {
          setAPIData(getData.data);                
      });
  }


  const onErase = (id) => {
      alert('Estas por elimiar un registro estas seguro ?')
      axios.get(url+`profile/delete/delete.php?id=${id}`)
      .then(() => {
          getData();
      })
  }

  const onDelete = (id) => {
      axios.get(url+`profile/delete/index.php?id=${id}`)
      .then(() => {
          getData();
      })
  }

  const onActive = (id) => {
      axios.get(url+`profile/delete/active.php?id=${id}`)
      .then(() => {
          getData();
      })
  }
  
  const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    };

 const registerUser =  (event) => {
    event.preventDefault();
    const registro = {
          name: nameUser,
          lastname: lastnameUser,
          phone: phoneUser,
          email: emailUser,
          password: passwordUser,
         
       }
    axios.post(url+`profile/create/register.php`,registro)
       .then((res) => {
          let {data} = res;
          setShow(false);              
          if(Number(data.success) === 1){
              createImageNew(data.message);
             
              
             
              event.target.reset();
              if(message){
                  setMessage(false);
              }
          }
          setMessage(data.message)
          
     
       })
      .catch(function (res) {
          
    });
    getData();

  }

  

  const createImageNew = (variable) => {        
      const formData1 = new FormData();
            formData1.append('file',file1);
            formData1.append('id',variable);        
      axios.post(url+`profile/create/image.php`, formData1)
      .then((res) => {
            let {data} = res;                           
            if(data.success && data.message){              
                if(data.success === 1){
                    setPreview1(null);
                    setImage1(null); 
                    setFile1(null);                
                }
            }
          })
          .catch(function (res) {
            console.log(res)
          });
          getData();
  };



  const handleShowNew = () => {
    if(message){
      setMessage(null);
    }
    clearImg();
    setSelectedOption(null);     
    setShow(true)
  };

  const clearImg = () => {
      setImage1(null);
      setPreview1(null);
  };

  //editar perfil 


      const handleChange=e=>{
      const {name, value}=e.target;
          setDatoSeleccionado((prevState)=>({
            ...prevState,
            [name]: value
          }));
    }


    const onDownload1 = () => {
        generateDownload1(image1, croppedArea);
     };

     const generateDownload1 = async (imageSrc, crop) => {
     if (!crop || !imageSrc) {
        return;
        }
        const canvas = await getCroppedImg(imageSrc, crop);
        const canvasDataUrl = canvas.toDataURL("image/jpeg");
        const convertedUrlToFile = dataURLtoFile(
              canvasDataUrl,
              "cropped-image.jpeg"
           );
        setFile1(convertedUrlToFile);      
        const reader = new FileReader();
           reader.readAsDataURL(convertedUrlToFile);
           reader.addEventListener("load", () => {
              setPreview1(reader.result);
              setShow3(false);            
           });
              
     };

      const updateImage = (variable) => {        
      const formData1 = new FormData();
            formData1.append('file',file1);
            formData1.append('id',variable);        
      axios.post(url+`profile/update/image.php`, formData1)
      .then((res) => {
            let {data} = res;                           
            if(data.success && data.message){              
                if(data.success === 1){
                    setPreview1(null);
                    setImage1(null); 
                    setFile1(null)                
                }

            }
          })
          .catch(function (res) {
            console.log(res)
          });
          getData();
      }

      

      const updateUser = async (event) => { 
         
            event.preventDefault();
            await axios.post(url+`profile/update/update.php`,datoSeleccionado)

               .then((res) => {
                  let {data} = res;                  
                  if(data.success && data.message){                        
                      updateImage(datoSeleccionado.id);                                      
                      setShow2(false);
                      setDatoSeleccionado({
                          id: '',
                          name: '',
                          last_name: '',
                          phone: '',
                          email: '',
                          password:''                                                
                      })
                      getData()
                  }
                  
             
               })
              .catch(function (res) {
                  
            });
            event.target.reset();
            
      }





   const setData=(elemento)=>{

      setDatoSeleccionado(elemento);
     
      setImage1(elemento.avatar_url);
      setPreview1(url+'profile/image/'+elemento.avatar_url);     
      setShow2(true)
  }



  return (
    <>
<div className="container mx-auto pt-5">
 <div className="row pt-3">
  <div className="col-1">
    <NavLink exact to={'/dashboard'} >
      <h1><BsIcons.BsArrowLeftShort className="app-color display-1" /></h1>
    </NavLink>	
  </div>
  <div className="col">
    <h1 className='titulo-1 pt-3'>Usuarios</h1>
  </div>
</div>
 <div className="pt-5 mb-3">
    <div className="input-group ">
       <input placeholder="Buscar" className="form-control form-control-sm" type="text" onChange={(e) => setFiltro(e.target.value)} />
       <span className="input-group-text" id="basic-addon2">
          <BsIcons.BsSearch/>
       </span>
    </div>
 </div>
 <div className="text-end">
    <div >
       <h1>
          <BsIcons.BsPlusCircleFill onClick={handleShowNew}/>
       </h1>
    </div>
 </div>
 {APIData.filter(datos => datos.name.toLocaleLowerCase().includes(filtro.toLocaleLowerCase())).map((data,index) => {
 return (
 <div key={index}>
 {(data.id_users_status) ==='1'? 
 <div className='card shadow mb-3' key={data.id}>
    <div className='card-body'>
       <div className='row align-items-center'>
          <div className='col-1'>
             <span className="account-user-avatar ">
             <img src={url+'profile/image/'+data.avatar_url} alt="amuam"  className="rounded-circle img-fluid"/>
             </span>
          </div>
          <div className='col'>{data.name},{data.last_name}</div>            
          <div className='col-2'>{data.phone}</div>
          <div className='col-3'>{data.email}</div>
          {(data.id_users_status) ==='1'?
          <div className="col-1 text-center "  onClick={() =>
             onDelete(data.id)}>
             <h3>
                <BsIcons.BsToggleOn />
             </h3>
          </div>
          :
          <div className="col-1 text-center "  onClick={() =>
             onActive(data.id)}>
             <h3>
                <BsIcons.BsToggleOff />
             </h3>
          </div>
          }                  
          <div className='col-1 text-center '>
             <div  data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={()=>
                setData(data)}>
                <div className="">
                   <h3>
                      <BsIcons.BsThreeDotsVertical />
                   </h3>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 :
 <div className='card shadow mb-3' key={data.id}>
    <div className='card-body' >
       <div className=" " id={data.id}>
       <div className='row align-items-center'>
          <div className='col-1'>
             <span className="account-user-avatar ">
             <img src={url+'profile/image/'+data.avatar_url} alt="kp"  className="rounded-circle img-fluid" style={{ filter: "grayscale(100%)" }}/>
             </span>
          </div>
          <div className='col'>{data.name},{data.last_name}</div>            
          <div className='col-2'>{data.phone}</div>
          <div className='col-3'>{data.email}</div>
          {(data.id_users_status) ==='1'?
          <div className=" text-center "  onClick={() =>
             onDelete(data.id)}>
             <h3>
                <BsIcons.BsToggleOn />
             </h3>
          </div>
          :
          <div className="col-1 text-center "  onClick={() =>
             onActive(data.id)}>
             <h3>
                <BsIcons.BsToggleOff />
             </h3>
          </div>
          }                  
          <div className='col-1 text-center'>
             <div  data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar" onClick={() =>
                onErase(data.id)}>
                <h3>
                   <BsIcons.BsTrash />
                </h3>
             </div>
          </div>
       </div>
       </div>
    </div>
 </div>
 }
 </div>
 )
 })}  
</div>
<Modal show={show} size="lg" onHide={handleClose}>
 <Modal.Header closeButton></Modal.Header>
 <Modal.Body>
    <div className="container  mb-3">
       <div className="col text-center">
          {image1  || !image1 === 'null' || !image1 === '' ? (
          <>                     
          <img src={preview1} alt="" className="rounded-circle account-user img-fluid" width={100} onClick={() => {                                  
          setPreview1(null);
          setImage1(null)}}/>
          </>
          ):(
          <>
          <input type="file" name="image1" id="image1" accept="image1/*" capture="camera" 
             onChange={(event) => { 
          const file = event.target.files[0];  ; 
          const reader = new FileReader();
          reader.onloadend = () => {
          setImage1(reader.result);
          };
          reader.readAsDataURL(file);
          setShow1(true)
          }} 
          ref={inputRef1} style={{ display: "none" }}/>
          <h1>
             <BsIcons.BsPlusCircleFill onClick={triggerFileSelectPopup1}/>
          </h1>
          </>
          )}
          <Modal show={show1} size="lg" onHide={handleClose1}>
             <Modal.Header closeButton></Modal.Header>
             <Modal.Body>
                <div className='containers'>
                   <div className='container-cropper'>
                      <div className='cropper'>
                         <Cropper image={image1} crop={crop} zoom={zoom} aspect={2/2} cropShape="round" showGrid={false} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
                      </div>
                      <div className='slider'>
                         <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) =>
                         setZoom(zoom)}/>
                      </div>
                   </div>
                   <div className='container-buttons'>
                      <div className="col-1"></div>
                      <button className='col-3 btn btn-primary btn-lg' onClick={onDownload}>
                         <BsIcons.BsCheckLg />
                      </button>
                   </div>
                </div>
             </Modal.Body>
          </Modal>
       </div>
      
       <br/><br/>
       <div className="center">
          <div className=" col-12 pt-1">
             <form onSubmit={registerUser}>
                <div className="row">
                   <div className="col-6 mb-2">
                      <small>Nombre</small>                                                                 
                      <input name="name"  type="text" className="form-control" onChange={(e) => setNameUser(e.target.value)} pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"  maxLength={20} required/>                                
                   </div>
                   <div className="col-6 mb-2">
                      <small>Apellido</small>                                
                      <input name="lastname"  type="text"  className="form-control" onChange={(e) => setLastname(e.target.value)} pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"   maxLength={20} required/>                                
                   </div>
                </div>
                <div className="mb-2">
                   <small>Teléfono</small>                                
                   <input name="phone"  type="text"   className="form-control" onChange={(e) => setPhone(e.target.value)}  minLength={10} maxLength={10} pattern="[0-9]+$" required/>
                </div>
                <div className="mb-2">
                   <small>Correo electrónico</small>                                
                   <input name="email"  type="email"  className="form-control" onChange={(e) => setEmail(e.target.value)}  required/>                             
                </div>
                <small>Contraseña</small>
                <div className="col-6">
                   <div className="input-group mb-3">
                      <input name="password"  type={passwordType} className="form-control " onChange={(e) => setPassword(e.target.value)}  minLength={6} maxLength={6} required/>
                      <div  className="input-group-text app-click" onClick={togglePassword}>
                         { passwordType==="password"? 
                         <small>Mostrar</small>
                         :
                         <small>Ocultar</small>
                         }
                      </div>
                   </div>
                </div>
                <br/>
                {message && (
                <div className="col-12 alert alert-primary" onClick={()=> setMessage(null)}>{message}</div>
                )}
                <div className="d-grid  mb-4">
                   <button type="submit" className="btn btn-primary shadow" >Continuar</button>
                </div>
             </form>
          </div>
       </div>
    </div>
 </Modal.Body>
</Modal>
<Modal show={show2} size="lg" onHide={handleClose2}>
 <Modal.Header closeButton></Modal.Header>
 <Modal.Body>
    <div className="container  mb-3">
       <div className="col text-center">
          <div className="avatar position-relative" >
             <img src={preview1} alt="" className="rounded-circle account-user img-fluid " width={100}/>
             <input type="file" name="image1" id="image1" accept="image1/*" capture="camera" 
                onChange={(event) => { 
             const file = event.target.files[0];  ; 
             const reader = new FileReader();
             reader.onloadend = () => {
             setImage1(reader.result);
             };
             reader.readAsDataURL(file);
             setShow3(true)
             }} 
             ref={inputRef1} style={{ display: "none" }}/><span className="position-absolute translate-middle badgesA rounded-pill " onClick={triggerFileSelectPopup1}><img src={cam} alt="cam"/></span> 
          </div>
          
          <Modal show={show3} size="lg" onHide={handleClose3}>
             <Modal.Header closeButton></Modal.Header>
             <Modal.Body>
                <div className='containers'>
                   <div className='container-cropper'>
                      <div className='cropper'>
                         <Cropper image={image1} crop={crop} zoom={zoom} aspect={2/2} cropShape="round" showGrid={false} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
                      </div>
                      <div className='slider'>
                         <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) =>
                         setZoom(zoom)}/>
                      </div>
                   </div>
                   <div className='container-buttons'>
                      <div className="col-1"></div>
                      <button className='col-3 btn btn-primary btn-lg'  onClick={onDownload1}>
                         <BsIcons.BsCheckLg />
                      </button>
                   </div>
                </div>
             </Modal.Body>
          </Modal>
          
       </div>
       
       <div className="center">
          <div className=" col-12 pt-1">
             
             <form onSubmit={updateUser}>
                <div className="row">
                   <div className="col-6 mb-2">
                      <small>Nombre</small>
                      <input type="hidden" name="id" value={datoSeleccionado.id}/>
                      <input name="name"  type="text" className="form-control" value={datoSeleccionado && datoSeleccionado.name}  onChange={handleChange} pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"  maxLength={20} />                                
                   </div>
                   <div className="col-6 mb-2">
                      <small>Apellido</small>                                
                      <input name="last_name"  type="text"  className="form-control" value={datoSeleccionado && datoSeleccionado.last_name} onChange={handleChange} pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"   maxLength={20} />                                
                   </div>
                </div>
                <div className="mb-2">
                   <small>Teléfono</small>                                
                   <input name="phone"  type="text"   className="form-control" value={datoSeleccionado && datoSeleccionado.phone} onChange={handleChange} minLength={10} maxLength={10} pattern="[0-9]+$" />
                </div>
                <div className="mb-2">
                   <small>Correo electrónico</small>                                
                   <input name="email"  type="email"  className="form-control" value={datoSeleccionado && datoSeleccionado.email} onChange={handleChange}/>                             
                </div>
                <div className=" mb-2">
                           <small>Cambiar contraseña</small>
                           <div className="input-group mb-3">
                              <input type={passwordType} className="form-control" name="password"  onChange={handleChange} minLength={6} maxLength={10} autoComplete="false" required/>                                                                  
                              <div  className="input-group-text app-click" onClick={togglePassword}>
                                 { passwordType==="password"? 
                                 <small>Mostrar</small>
                                 :
                                 <small>Ocultar</small>
                                 }
                              </div>
                           </div>
                        </div>

                <br/>
                <div className="d-grid  mb-4">
                   <button type="submit" className="btn btn-primary shadow" >Continuar</button>
                </div>
             </form>
          </div>
       </div>
    </div>
 </Modal.Body>
</Modal>
     
    </>
)}