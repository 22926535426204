import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useState } from "react";
import { url } from "../../service/EndPoint";

function ConsultaModal({ show, onHide, data, actualizar }) {
  function formatearFecha(fecha) {
    if (!(fecha instanceof Date)) {
      fecha = new Date(fecha);
    }

    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    const diaFormateado = dia.toString().padStart(2, "0");
    const mesFormateado = mes.toString().padStart(2, "0");

    const fechaFormateada = `${diaFormateado}/${mesFormateado}/${año}`;

    return fechaFormateada;
  }
  useEffect(() => {
    setSolicitud(data);
  }, [data]);

  const [solicitud, setSolicitud] = useState("");

  const onChangeForm = (e) => {
    setSolicitud({ ...solicitud, [e.target.name]: e.target.value });
  };
  const [activar, setActivar] = useState(true);

  function enviar() {
    axios
      .post(url + `solicitudes/editarConsulta.php`, solicitud)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          if (data.success === 1) {
            actualizar();
            onHide()
          }
        }
      })
      .catch(function (res) {});
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>Fecha solicitud {data && formatearFecha(data.create_at)}</div>
        </div>
        <form>
          <div className="row">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="nombre"
                    placeholder="NOMBRE"
                    value={solicitud && solicitud.nombre}
                    onChange={onChangeForm}
                  />
                  <label htmlFor="Nombre">NOMBRE</label>
                  <div className="invalid-feedback">
                    Por favor completa este campo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <input
                  type="mail"
                  className="form-control"
                  name="mail"
                  value={solicitud && solicitud.mail}
                  onChange={onChangeForm}
                  placeholder="E.MAIL"
                />
                <label htmlFor="mail">E.MAIL</label>
                <div className="invalid-feedback">
                  Por favor completa este campo
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <textarea
                  className="form-control"
                  name="consulta"
                  value={solicitud && solicitud.consulta}
                  onChange={onChangeForm}
                  id="floatingTextarea"
                  defaultValue={""}
                  style={{ height: "100px" }}
                />
                <label htmlFor="floatingTextarea">Consulta</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Estado"
                  name="estado"
                  onChange={onChangeForm}
                >
                  <option selected>
                    {Number(solicitud && solicitud.estado) === 0 && "Nuevo"}
                    {Number(solicitud && solicitud.estado) === 1 &&
                      "Pendiente"}{" "}
                    {Number(solicitud && solicitud.estado) === 2 && "Cerrada"}
                  </option>
                  <option value={0}>Nuevo</option>
                  <option value={1}>Pendiente</option>
                  <option value={2}>Cerrado</option>
                </select>
                <label htmlFor="floatingSelect">Estado</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row g-2">
              <button
                className="btn app-button btn-block text-white"
                type="button"
                onClick={() => activar && enviar()}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ConsultaModal;
