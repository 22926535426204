import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/image/logo_cabecera.png";
import Accordion from 'react-bootstrap/Accordion';
import Image from "react-bootstrap/Image";
import { url } from "../../service/EndPoint";
import axios from "axios";

const PreguntasFrecuentes = () => {
	const [data, setData] = useState([])

	function getData(){
		axios
		.get(url + "preguntas/list/categoriasActivas.php")
		.then((getData) => {
		  setData(getData.data);
		});
	}
	useEffect(()=>{
		getData()
	},[])

  return (
    <div className="quienes">
      <header id="load">
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <nav
              className="navbar navbar-expand-lg navbar-dark  fixed-top"
              style={{ background: "#292740", color: "#fff !important" }}
            >
              <div className="container-fluid">
                <a
                  className="navbar-brand"
                  href="#"
                >
                  <Image src={logo} width="180px" />
                </a>

                  <div className="menu ">
                    <div className="menu menu-titulo">
                      <NavLink exact to={"/home"}>
                        <div className="nenu-item">VOLVER</div>
                      </NavLink>
                    </div>
                  </div>
                </div>
             
            </nav>
          </div>
        </div>
      </header>
      <div className="container pt-5 pb-5">
        <div className="row pt-5">
			<div className="pt-5 mb-3">
			<div className="titulo-1 ">
                PREGUNTAS FRECUENTES
              </div>
			</div>
			<div className="pt-3">
				{data.length > 0 ?
				<Accordion>
					{data && data.map((a,index)=>(
					<Accordion.Item eventKey={index} key={index}>
						<Accordion.Header>{a.nombre}</Accordion.Header>
						<Accordion.Body>
							{a.descripcion}
						</Accordion.Body>
				</Accordion.Item>
					))}				

    			</Accordion>
				:
				<div className="container">
					No hay preguntas en este momento.
				</div>
				}
				</div>           
      </div>



      </div>

	</div>
  );
};

export default PreguntasFrecuentes;