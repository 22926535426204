import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { url } from "../../service/EndPoint";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import logo from "../../assets/image/logo_cabecera.png";
import Card from "react-bootstrap/Card";
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import Simulator from "../../components/Simulator/Simulator";
import "swiper/css";
import "./swiper.css";
import TypingText from "../../components/TypingText/TypingText";
import SliderApp from "../../components/SliderApp/SliderApp";
import coop from "../../assets/image/paralax-home-socios.jpg";
import NavbFooter from "../../components/NavFooter";


export default function Home() {
  const [category,setCategory]= useState([])
  const [convenios, setConvenios] = useState([]);
  const [formData, setFormData] = useState({
    direccion: "",
    telefono: "",
    telefonoWhatsapp: "",
    textoAnimado: "",
    quienes: "",
    cuit: "",
    razonSocial: "",
    condicion: "",
  });
  const numero = formData.telefonoWhatsapp;
  useEffect(() => {
    axios
    .post(url + "identidad/datos.php")
    .then((getData) => {
      setFormData(getData.data[0]);
      localStorage.setItem('data',JSON.stringify(getData.data[0]))
    });
    axios
      .post(url + "category/list/list.php")
      .then((getData) => {
        setCategory(getData.data);
        
      });
      axios
      .post(url + "convenios/list/list.php")
      .then((getData) => {
        setConvenios(getData.data);
      });
      
  }, []);
  const categories = category && category.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <NavLink exact to={"/servicios/"+ item.id}>
        <Card  style={{ border: "0px" }}>
          <Card.Img variant="top" src={url + "category/image/" + item.urlImage} style={{ borderRadius: "0px" }} />
          <Card.Body>
            <Card.Title>{item.nombre}</Card.Title>
          </Card.Body>
        </Card>
        </NavLink>
      </SwiperSlide>
    );
  });
  const categoriesConv = convenios && convenios.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <NavLink exact to={"/convenios/"+ item.id}>
        <Card  style={{ border: "0px" }}>
          <Card.Img variant="top" src={url + "convenios/image/" + item.urlImage} style={{ borderRadius: "0px" }} />
          <Card.Body>
            <Card.Title>{item.nombre}</Card.Title>
          </Card.Body>
        </Card>
        </NavLink>
      </SwiperSlide>
    );
  });
  const [solicitud, setSolicitud] = useState({
    nombre:'',
    mail:'',
    consulta:'',
  });
  const onChangeValue = (e) => {
    setSolicitud({
      ...solicitud,
      [e.target.name]: e.target.value,
    });
  };
  const [activar, setActivar] = useState(true);
  const [exito, setExito] = useState(true);


  function validarCorreoElectronico(correo) {    
    var expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;    
    return expresionRegular.test(correo);
  }

  function enviar() {
    setActivar(false);
    if (
      solicitud.nombre === "" ||
      solicitud.consulta === "" ||
      solicitud.mail === "" ||
      !validarCorreoElectronico(solicitud.mail)
    ) {
      setActivar(true);
      alert("Por favor, debes completar los campos y ingresar un correo valido");
    } else {
      axios
        .post(url + `solicitudes/consulta.php`, solicitud)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {});
    }
  }
  const texto = formData.textoAnimado;
  return (
    <>
      <div className="container">
        <header id="load">
          <div id="layoutDefault">
            <div id="layoutDefault_content">
              <nav
                className="navbar navbar-expand-lg navbar-dark  fixed-top d-none d-sm-none d-md-block"
                style={{ background: "#292740", color: "#fff !important" }}
              >
                <div className="container-fluid">
                  <a
                    className="navbar-brand d-none d-sm-none d-md-block"
                    href="#"
                  >
                    <Image src={logo} width="180px" />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarNav"
                  >
                    <div className="menu ">
                      <div className="menu menu-titulo">
                        <a href="#servicios">
                          <div className="nenu-item">SERVICIOS</div>
                        </a>
                        <a href="#convenios">
                          <div className="nenu-item">CONVENIOS</div>
                        </a>
                        <NavLink exact to={"/alta-socio"}>
                          <div className="nenu-item">SOCIOS</div>
                       </NavLink>
                        <NavLink exact to={"/quienes-somos"}>
                          <div className="nenu-item">NOSOTROS</div>
                        </NavLink>
                        <a href="#contacto">
                          <div className="nenu-item">CONTACTO</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>

        <div className="btnCenter">
          <div className="d-none d-sm-none d-md-block text-end">
            <a
              href={`https://api.whatsapp.com/send/?phone=%2B549${numero}&text=Buen+día+quiero+información&app_absent=0`}
              target="_blank"
            >
              <img
                src="https://cronogram.com.ar/assets/logo-wasap.png"
                className="shadow"
                style={{ borderRadius: "50px 50px" }}
                width={80}
                height={80}
              />
            </a>
          </div>
          <div className="d-sm-block d-md-none text-end">
            <a
              href={`https://api.whatsapp.com/send/?phone=%2B549${numero}&text=Buen+día+quiero+información&app_absent=0`}
              target="_blank"
            >
              <img
                src="https://cronogram.com.ar/assets/logo-wasap.png"
                className="shadow"
                style={{ borderRadius: "50px 50px" }}
                width={55}
                height={55}
              />
            </a>
          </div>
        </div>
        <br />
        <div className="row">
          <SliderApp />
          <div className="col-md-7 col-sm-12">
            <div className="container">
              <br className="d-none d-sm-none d-md-block" />
              <br className="d-none d-sm-none d-md-block" />
              <div className="titulo-1 text-center">
                ASOCIACION MUTUAL
                <br /> ACTIVA MENDOZA
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <div className="box">
              <div className="text-center">
                <span className="titulo">
                  <TypingText texto={formData && formData.textoAnimado} style={{ color: "black" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <section id="servicios" className="py-5 bg-light">
          <br className="d-none d-sm-none d-md-block" />
          <br className="d-none d-sm-none d-md-block" />
          <div className="row mb-3">
            <div className="col-md-8 col-sm-12 mx-auto">
              <div className="container ">
                <div className="titulo-1 text-start">
                  CONOCÉ LOS SERVICIOS QUE TENEMOS PARA VOS
                </div>
              </div>
            </div>
          </div>
          <Swiper slidesPerView={3} spaceBetween={30} className="mySwiper">
            {categories}
          </Swiper>
          <div></div>
        </section>
        <section style={{ background: "#bfd7dd" }}>
          <div className="row pt-5 pb-5">
            <div className="col-md-8 col-sm-12 mx-auto">
              <div className="container ">
                <div className="titulo-1 text-center">AYUDA ECONOMICA</div>
              </div>
            </div>
            <Simulator />
          </div>
        </section>
        <div
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" +
              coop +
              ")",
            height: "400px",
            backgroundSize: "cover",
            backgroundPosition: "50% center",
          }}
        ></div>
        <section id="convenios" className="py-5 bg-light">
          <div className="row pt-5">
            <div className="col-12">
              <div className="box-1">
                <div className="text-center">
                  {" "}
                  Con Amuam tenés ahorros y beneficios.
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="container">
                <div className="titulo-1 text-start">
                  CONVENIOS QUE TENEMOS PARA VOS:
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <Swiper slidesPerView={3} spaceBetween={30} className="mySwiper">
            {categoriesConv}
          </Swiper>
        </section>

        <section id="contacto" style={{ background: "#bfd7dd" }}>
          <br className="d-none d-sm-none d-md-block" />
          <br className="d-none d-sm-none d-md-block" />
          <div className="row pt-3">
            <div className="col-12">
              <div className="container mb-5">
                <div className="titulo-1">Alguna duda? Envianos un mensaje</div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header style={{ color: "grey" }}>
                      Consultar
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        {exito ?
                        <form>
                          <div className="row">
                            <div className="row g-2">
                              <div className="col-md">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="nombre"
                                    placeholder="NOMBRE"
                                    onChange={onChangeValue}
                                  />
                                  <label htmlFor="Nombre">NOMBRE</label>
                                  <div className="invalid-feedback">
                                    Por favor completa este campo
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="row g-2">
                              <div className="form-floating">
                                <input
                                  type="mail"
                                  className="form-control"
                                  name="mail"
                                  placeholder="E.MAIL"
                                  onChange={onChangeValue}
                                />
                                <label htmlFor="mail">E.MAIL</label>
                                <div className="invalid-feedback">
                                  Por favor completa este campo
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="row g-2">
                              <div className="form-floating">
                                <textarea
                                  className="form-control"
                                  name="consulta"
                                  onChange={onChangeValue}
                                  id="floatingTextarea"
                                  defaultValue={""}
                                  style={{ height: "100px" }}
                                />
                                <label htmlFor="floatingTextarea">
                                  Consulta
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="text-center pt-3">
                            <button className="btn app-button text-white"
                            type="button"
                            onClick={() => activar && enviar()}
                            >
                              Enviar
                            </button>
                          </div>
                        </form>
                        :
                        <div className="text-center">
                            Enviamos tu consulta !
                        </div>
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div
            className="container text-start mb-5 "
            style={{ color: "#292740" }}
          >
            <h3>
              <strong>Encontranos</strong>
            </h3>
            <br />
            {formData && formData.direccion}
            <br />
            {formData && formData.horario}
          </div>
          <div id="googlemaps" className="h-100">
            <iframe
              width="100%"
              height="400"
              src="https://maps.google.com/maps?q=Paseo%20Sarmiento%20N%C2%B0127%20Oficina%2011&t=&z=15&ie=UTF8&iwloc=&output=embed"
              frameBorder={0}
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
            />
          </div>
        </section>
        <Footer data={formData}/>
        <div className="d-md-none d-sm-block">
          <NavbFooter  />
        </div>
      </div>
    </>
  );
}
