import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img1 from "../../assets/image/slide-1-home.jpg";
import img2 from "../../assets/image/slide-2-home.jpg";


const SliderApp = ({}) => {
	return  (
			<Carousel>
				<Carousel.Item>
					<img src={img1} className="d-block w-100" alt="..."/>
				<Carousel.Caption>
				</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img src={img2} className="d-block w-100" alt="..."/>
				<Carousel.Caption>
				</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
	)
};

SliderApp.propTypes = {};

export default SliderApp;