import { NavLink } from "react-router-dom";
import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import Offcanvas from "react-bootstrap/Offcanvas";
import { url } from "../service/EndPoint";
import "./Navb.css";

function DashNavbFooter(props) {
  const auth = props.auth;
  const user = auth.rootState.theUser

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <div className="dashFooterStyle">
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="contenedor-menu">
          <div className="text-center">
            <div className="text-center">
            <img src={url+'profile/image/'+user.avatar_url} alt="" className="rounded-circle account-user img-fluid" width={100} style={{
                     borderRadius: '50px',
                     border: '4px solid #ffffff42',
                     cursor: "pointer"
                  }} />
            </div>


            <strong>{user && user.name}</strong><br/>
            {user && user.email}
          </div>
          <div>
              <button className="btn app-btn" onClick={auth.logoutUser}>
                Cerrar sesion
              </button>
          </div>
        </div>
              
       
        </Offcanvas.Body>
      </Offcanvas>
      <div id="footer-bar" className="footer-bar footer-bar-detached">
        <NavLink exact to="/dashboard">
          <AiIcons.AiOutlineHome className="font-17" />
          <span>Inicio</span>
        </NavLink>
        <NavLink exact to="/servicios-categoria">
          <AiIcons.AiOutlineShop className="font-17" />
          <span>Servicios</span>
        </NavLink>
        <NavLink exact to="/convenios-categoria">
          <BsIcons.BsHandbag className="font-17" />
          <span>Convenios</span>
        </NavLink>
        <NavLink exact to="/notificaciones">
          <BsIcons.BsEnvelope className="font-17" />
          <span>Notifcaciones</span>
        </NavLink>
        <div onClick={handleShow}>
          <BsIcons.BsGrid className="font-17" />
          <span>Menú</span>
        </div>
      </div>
    </div>
  );
}

export default DashNavbFooter;
