import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/image/logo_cabecera.png";
import Image from "react-bootstrap/Image";
import { url } from "../../service/EndPoint";
import axios from "axios";
import './ConveniosLista.css';
import Card from 'react-bootstrap/Card';

const ConveniosLista = ({}) => {
	const idUrl = window.location.href
	const id = idUrl.split('/').pop()
 	const [data, setData] = useState([])

	function getData(){
		axios
		.get(url + "conv/list/itemhome.php?categoria="+id)
		.then((getData) => {
		  setData(getData.data);
		});
	}
	useEffect(()=>{
		getData()
	},[])



	return <div className='convenioslista'>
				    <header id="load">
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <nav
              className="navbar navbar-expand-lg navbar-dark  fixed-top"
              style={{ background: "#292740", color: "#fff !important" }}
            >
              <div className="container-fluid">
                <a
                  className="navbar-brand"
                  href="#"
                >
                  <Image src={logo} width="180px" />
                </a>

                  <div className="menu ">
                    <div className="menu menu-titulo">
                      <NavLink exact to={"/home"}>
                        <div className="nenu-item">VOLVER</div>
                      </NavLink>
                    </div>
                  </div>
                </div>
             
            </nav>
          </div>
        </div>
      </header>
      <div className="container pt-5 pb-5">
        <div className="row pt-5">
			<div className="pt-5 mb-3">
			<div className="titulo-1 ">
                CONVENIOS
              </div>
			</div>
			<div className="pt-3">
				{data.length > 0 ?
					<div className='row'>
					{data && data.map((a,index)=>(
					<div className="col-md-4 col-sm-12" key={index}>
						<Card style={{ width: '18rem' }}>
							<Card.Body className="text-center">
								<Card.Title>{a.nombre}</Card.Title>
								<Card.Subtitle className="mb-2 text-muted">{a.descripcion}</Card.Subtitle>
								<Card.Text>
								{a.vigencia}
								</Card.Text>
								<div className="text-center pt-3">
							<NavLink exact to="/mas-informacion">
                          <button
                            className="btn app-button text-white"
                            type="button"
                          >
                            Más información
                          </button>
						  </NavLink>
                        </div>

							</Card.Body>
						</Card>
					</div>
					))}				
				</div>
				:
				<div className="container">
					No hay convenios en este momento.
				</div>
				}
				</div>           
      </div>



      </div>
	</div>;
};



export default ConveniosLista;