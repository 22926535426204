import React, { useEffect } from 'react';
import Typed from 'typed.js';

function TypingText(props) {
  const data =JSON.parse(localStorage.getItem('data'))
	const texto = data ? data.textoAnimado : 'Igualdad de oportunidades, equidad y justicia social'
   useEffect(() => {
    // Configura las opciones de Typed.js
    const options = {
      strings: [texto], // Array de texto que Typed.js escribirá
      typeSpeed: 60, // Velocidad de escritura (en milisegundos)
      backSpeed: 30, // Velocidad de retroceso (en milisegundos)
      loop: true, // Si quieres que el texto se repita en un bucle
	  showCursor: false,
    };

    // Inicializa Typed.js en el elemento
    const typed = new Typed('.typing-text', options);

    // Limpia Typed.js cuando el componente se desmonta
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="typing-text titulo"></div>
  );
}

export default TypingText;
