import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { url } from "../../service/EndPoint";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";


function Preguntas() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [data, setData] = useState([]);
  const [editar, setEditar] = useState([]);
  const [registro, setRegistro] = useState({
    nombre: "",
    descripcion: "",
    estado: 1,
  });

  useEffect(() => {
    axios
      .post(url + "preguntas/list/list.php")
      .then((getData) => {
        setData(getData.data);
      });
   
  }, []);

  function getData() {
    axios
      .post(url + "preguntas/list/list.php")
      .then((getData) => {
        setData(getData.data);
      });
  }

  function handleSubmit(e) {
    
    e.preventDefault();
    const datas = new FormData();      
      datas.append("nombre", registro.nombre);
      datas.append("descripcion", registro.descripcion);
      axios.post(url + `preguntas/add/index.php`, datas, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();        
          setShow(false);
        }
      })
      .catch(function (res) {});
  }
  function handleSubmit1(e) {
    e.preventDefault();
    axios
      .post(url + `preguntas/put/editar.php`,  editar)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();
          setShow1(false);
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  const handleChange = (e) => {
    setRegistro({ ...registro, [e.target.name]: e.target.value });
  };

  const handleChange1 = (e) => {
    setEditar({ ...editar, [e.target.name]: e.target.value });
  };

  function onErase(id) {
    axios.get(url + `preguntas/delete/delete.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onDelete(id) {
    axios.get(url + `preguntas/delete/index.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onActive(id) {
    axios.get(url + `preguntas/delete/active.php?id=${id}`).then(() => {
      getData();
    });
  }


  return (
    <div className="container">
      <div className="text-start">
        <NavLink exact to={"/dashboard"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#292740",border:'0px' }}>
        <div className="card-body">
          <div className="text-center text-white">
            Adminitración de Preguntas
          </div>
        </div>
      </div>
      <div className="text-center " style={{ color: "#292740" }}>
        <small>
          Nota: Una vez creada la pregunta ingresa a esta para poder agregar el
          la respuesta.
        </small>
      </div>
      <div className="text-end  ">
        <h1>
          <BsIcons.BsPlusCircleFill
            onClick={handleShow}
            style={{ color: "#292740" }}
          />
        </h1>
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
        <h1>Agregar Pregunta</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>           
            <Col md={12}>
              <FloatingLabel
                controlId="floatingCodigo"
                label="Pregunta *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Pregunta"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={12}>

              <FloatingLabel
                controlId="floatingDescripcion"
                label="Respuesta *"
                className="mb-3"
              >
                <Form.Control
                      as="textarea"
                      style={{ height: '100px' }}
                  name="descripcion"
                  placeholder="Respuesta"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <div className="d-grid gap-2 pt-5">
            <button
              onClick={handleSubmit}
              className="btn app-button  text-white shadow"
            >
              Guardar
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {data &&
        data.map((datos, index) => (
          <div key={index}>
            {Number(datos.estado) === 1 ? (
              <>
                <div className="card shadow mb-3" >
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="text-center" style={{ flex: 1 }}>
                        <BsIcons.BsFillRecordFill
                          style={{ color: "#30ff24" }}
                        />
                      </div>
                      <div className="text-start active" style={{ flex: 3, }}>

                          <span style={{ color: "#292740 " }}>{datos.nombre}</span>
                 
                      </div>
                     {Number(datos.estado) === 1 ? (
                        <div className="text-end"
                          style={{ flex: 1 }}
                          onClick={() => onDelete(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOn />
                          </h3>
                        </div>
                      ) : (
                        <div className="text-end"
                          style={{ flex: 1 }}
                          onClick={() => onActive(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOff />
                          </h3>
                        </div>
                      )}
                      <div className="text-end" style={{ flex: 1 }}>
                        <div
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                          onClick={() => setEditar(datos)}
                        >
                          <div className="">
                            <h3>
                              <BsIcons.BsThreeDotsVertical
                                onClick={handleShow1}
                              />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={show1} size="lg" onHide={handleClose1}>
                  <Modal.Header closeButton>
                    <h1>Editar Pregunta</h1>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>                                      
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingCodigo"
                          label="Pregunta *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="nombre"
                            value={editar && editar.nombre}
                            placeholder="Pregunta"
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingDescripcion"
                          label="Respuesta *"
                          className="mb-3"
                        >
                          <Form.Control
                            as="textarea"
                            style={{ height: '100px' }}
                            name="descripcion"
                            value={editar && editar.descripcion}
                            placeholder="Descripción"
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <div className="d-grid gap-2 pt-5">
                      <button
                        onClick={handleSubmit1}
                        className="btn app-button  text-white shadow"
                      >
                        Guardar
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <>
                <div className="card shadow mb-3" key={index}>
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div  className="text-center" style={{ flex: 1 }}>
                        <BsIcons.BsFillRecordFill
                          style={{ color: "#f80f00" }}
                        />
                      </div>

                      <div className="text-start" style={{ flex: 3 }}>
                                 {datos.nombre}
                       
                      </div>
                      {Number(datos.estado) === 1 ? (
                        <div className="text-end"
                          style={{ flex: 1 }}
                          onClick={() => onDelete(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOn />
                          </h3>
                        </div>
                      ) : (
                        <div className="text-end"
                          style={{ flex: 1 }}
                          onClick={() => onActive(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOff />
                          </h3>
                        </div>
                      )}
                      <div className="text-end" style={{ flex: 1 }}>
                        <div
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Eliminar"
                          onClick={() => onErase(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsTrash />
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
    </div>
  );
}


export default Preguntas;