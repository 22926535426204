import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { url } from "../../../service/EndPoint";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../../auth/useAuth";
import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

const Entidad = () => {
  let { id } = useParams();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const auth = useAuth();
  const user = [auth.rootState.theUser][0];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [data, setData] = useState([]);
  const [editar, setEditar] = useState([]);
  const [registro, setRegistro] = useState({
    nombre: "",
    descripcion: "",
    categoria: Number(info.id),
    vigencia:"",
    estado: 1,
  });

  useEffect(() => {
    axios
      .get(
        url +
          "conv/list/item.php?categoria=" +
          Number(info.id))
      .then((getData) => {
        setData(getData.data);
      });
  }, []);

  function getData() {
    axios
      .get(
        url +
        "conv/list/item.php?categoria=" +
        Number(info.id))
      .then((getData) => {
        setData(getData.data);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(url + `conv/add/index.php`, registro)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();
          setShow(false);
        }
      })
      .catch(function (res) {});
  }
  function handleSubmit1(e) {
   
    e.preventDefault();
    axios
      .post(url + `conv/put/editar.php`, editar)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();
          setShow1(false);
        }
      })
      .catch(function (res) {
        
      });
  }

  const handleChange = (e) => {
    setRegistro({ ...registro, [e.target.name]: e.target.value });
  };

  const handleChange1 = (e) => {
    setEditar({ ...editar, [e.target.name]: e.target.value });
  };

  function onErase(id) {
    axios.get(url + `conv/delete/delete.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onDelete(id) {
    axios.get(url + `conv/delete/index.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onActive(id) {
    axios.get(url + `conv/delete/active.php?id=${id}`).then(() => {
      getData();
    });
  }

  return (
    <div className="container" >
      <div className="text-start">
        <NavLink exact to={"/convenios-categoria/"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#292740",border:'0px' }}>
        <div className="card-body">
          <div className="text-center text-white">
            {info && info.nombre}
          </div>
        </div>
      </div>
      <div className="text-center " style={{ color: "#292740" }}>
        <small>
           Agregar o actualiza el servicio.
        </small>
      </div>  
      <div className="text-end">
        <h1>
          <BsIcons.BsPlusCircleFill
            onClick={handleShow}
            style={{ color: "#292740" }}
          />
        </h1>
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
        <h1>Agregar Servicio</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <FloatingLabel
                controlId="floatingCodigo"
                label="Nombre *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Nombre"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={12}>
              <FloatingLabel
                controlId="floatingDescripcion"
                label="Descripcion *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="descripcion"
                  placeholder="Descripción"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={12}>
                        <FloatingLabel
                          controlId="floatingVigencia"
                          label="Vigencia *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="vigencia"
                            value={editar && editar.vigencia}
                            placeholder="Vigencia"
                            onChange={handleChange}
                          />
                        </FloatingLabel>
                      </Col>
          </Row>
          <div className="d-grid gap-2 pt-5">
            <button
              onClick={handleSubmit}
              className="btn app-button  text-white shadow"
            >
              Guardar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {data &&
        data.map((datos, index) => (
          <div key={index}>
            {Number(datos.estado) === 1 ? (
              <>
                <div className="card shadow mb-3 ">
                  <div className="card-body">
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="text-center" style={{ flex: 1 }}>
                        <BsIcons.BsFillRecordFill
                          style={{ color: "#30ff24" }}
                        />
                      </div>
                      <div className="text-start" style={{ flex: 6 }}>
                        {datos.nombre}
                      </div>
                        <div className="text-end" style={{ flex: 1 }}>                       
                          {Number(datos.estado) === 1 ? (
                            <div
                              
                              onClick={() => onDelete(datos.id)}
                            >
                              <h3>
                                <BsIcons.BsToggleOn />
                              </h3>
                            </div>
                          ) : (
                            <div                              
                              onClick={() => onActive(datos.id)}
                            >
                              <h3>
                                <BsIcons.BsToggleOff />
                              </h3>
                            </div>
                          )}
                        </div>
                          <div className="text-end" style={{ flex: 1 }}>
                            <div
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => setEditar(datos)}
                            >
                           
                                <h3>
                                  <BsIcons.BsThreeDotsVertical
                                    onClick={handleShow1}
                                  />
                                </h3>
                             
                            </div>
                          </div>
                       
                      </div>
                    </div>
                  </div>
               
                <Modal show={show1} size="lg" onHide={handleClose1}>
                  <Modal.Header closeButton>
                    <h1>Editar Servicio</h1>
                  </Modal.Header>
                  <Modal.Body>
                     <Row>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingCodigo"
                          label="Nombre *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="nombre"
                            placeholder="Nombre"
                            value={editar && editar.nombre}
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingDescripcion"
                          label="Descripcion *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="descripcion"
                            value={editar && editar.descripcion}
                            placeholder="Descripción"
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingVigencia"
                          label="Vigencia *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="vigencia"
                            value={editar && editar.vigencia}
                            placeholder="Vigencia"
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <div className="d-grid gap-2 pt-5">
                      <button
                        onClick={handleSubmit1}
                        className="btn app-button  text-white shadow"
                      >
                        Guardar
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <div className="card shadow mb-3 ">
                <div className="card-body">
                <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                    <div className="text-center" style={{ flex: 1 }}>
                      <BsIcons.BsFillRecordFill style={{ color: "#f80f00" }} />
                    </div>
                    <div className="text-start" style={{ flex: 6 }}>
                      {datos.nombre}
                    </div>
                   
                    <div className="text-end" style={{ flex: 1 }}>
                      
                        {Number(datos.estado) === 1 ? (
                          <div
                            
                            onClick={() => onDelete(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsToggleOn />
                            </h3>
                          </div>
                        ) : (
                          <div
                           
                            onClick={() => onActive(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsToggleOff />
                            </h3>
                          </div>
                        )}
                        </div>
                        <div className="text-end" style={{ flex: 1 }}>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Eliminar"
                            onClick={() => onErase(datos.id)}
                          >
                            <h3>
                              <BsIcons.BsTrash />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          
            )}
          </div>
        ))}
    </div>
  );
};

export default Entidad;
