import React from 'react';
import './Footer.css';
import * as BsIcons from "react-icons/bs";
import Image from "react-bootstrap/Image";
import logo from "../../assets/image/logo_cabecera.png";
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const Footer = () => {
	const data =JSON.parse(localStorage.getItem('data'))
	return <div className='footer' style={{background:'#292740', color: '#fff'}}>
				<div className='container'>
					<div className='row pt-5 pb-5'>
						<div className='col-md-4 col-sm-12'>
							<div>
								<ul>
									<li className='mb-3'>
										<Image src={logo} width="180px"/><br/>
									</li>
									<li>
										Si tiene dudas y quiere ser contactado por nosotros,
									</li>
									<li>
										complete el formulario con sus datos y consulta. Le
									</li>
									<li>
										responderemos en breve.
									</li>									
								</ul>
							</div>
						</div>
						<div className='col-md-4 col-sm-12'>
							<div>
								<ul>
									<li className='mb-3'>
									<BsIcons.BsChevronRight/> Dirección:<br/> {data && data.direccion}
									</li>
									<li className='mb-3'>
									<BsIcons.BsChevronRight/> Teléfono: {data && data.telefono}
									</li>
									<li className='mb-3'>
									<BsIcons.BsChevronRight/> Correo: {data && data.email}
									</li>
								</ul>
							</div>
						</div>
						<div className='col-md-4 col-sm-12'>
							<div>
								<ul>
									<li className='mb-3'>
										<NavLink to='/preguntas-frecuentes' style={{color: '#fff'}}>
											<BsIcons.BsChevronRight/> PREGUNTAS FRECUENTES
										</NavLink>									
									</li>
									<li className='mb-3'>
									<NavLink to='/alta-socio' style={{color: '#fff'}}>
									<BsIcons.BsChevronRight/> SOLITAR ALTA
									</NavLink>	
									</li>
									<li className='mb-3'>
										<NavLink to='/login' style={{color: '#fff'}}>
											<BsIcons.BsChevronRight/> INGRESAR
										</NavLink>									
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>;
};

Footer.propTypes = {};

export default Footer;