import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/image/logo_cabecera.png";
import Image from "react-bootstrap/Image";
import { url } from "../../service/EndPoint";
import axios from "axios";
import "./ListasServicios.css";
import Card from "react-bootstrap/Card";

const ListasServicios = ({}) => {
  const idUrl = window.location.href;
  const id = idUrl.split("/").pop();
  const [data, setData] = useState([]);

  function getData() {
    axios
      .get(url + "category/list/itemhome.php?categoria=" + id)
      .then((getData) => {
        setData(getData.data);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  

  return (
    <div className="convenioslista">
      <header id="load">
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <nav
              className="navbar navbar-expand-lg navbar-dark  fixed-top"
              style={{ background: "#292740", color: "#fff !important" }}
            >
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <Image src={logo} width="180px" />
                </a>

                <div className="menu ">
                  <div className="menu menu-titulo">
                    <NavLink exact to={"/home"}>
                      <div className="nenu-item">VOLVER</div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="container pt-5 pb-5">
        <div className="row pt-5">
          <div className="pt-3">
            {data.length > 0 ? (
              <div>
                {data &&
                  data.map((a, index) => (
                    <div className="row">
                      <div className="col-md-12 col-sm-12" key={index}>
                        <div className="titulo-1 ">{a.nombre}</div>
                      </div>
                      <div className="col-md-6 col-sm-12 pt-3">
                        {a.descripcion}
						<div className="text-center pt-5">
							<NavLink exact to="/mas-informacion">
                          <button
                            className="btn app-button text-white"
                            type="button"
                          >
                            Más información
                          </button>
						  </NavLink>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 pt-3">
                        <img
                          src={url + "category/image/" + a.urlImage}
                          className="img-fluid"
                        />

                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="container">No hay servicios en este momento.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListasServicios;
