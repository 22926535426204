import React, { useState } from "react";
import axios from "axios";
import { url } from "../../service/EndPoint";
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";

export default function Restore() {
  const [mostrarComponente, setMostrarCompomemte] = useState(true);
  const [mostarMsg, setMostarMsg] = useState(false);
  const [recover, setRecover] = useState(true);

  const initialState = {
    userInfo: {
      email: ""
    },
    errorMsg: "",
    successMsg: ""
  };
  const [state, setState] = useState(initialState);

  const onChangeValue = (e) => {
    setState({
      ...state,
      userInfo: {
        ...state.userInfo,
        [e.target.name]: e.target.value
      }
    });
  };

  const submitForm = (event) => {
    setRecover(false);
    setState({
      ...state,
      errorMsg: ""
    });
    event.preventDefault();
    axios
      .post(url + `auth/restorePassword.php`, {
        email: state.userInfo.email
      })
      .then((res) => {
        let { data } = res;
        console.log(data)
        if (data.success && data.message) {
         
          setMostrarCompomemte(false);
          setMostarMsg(true);
        } else {
          setState({
            ...state,
            successMsg: "",
            errorMsg: data.message
          });
          setRecover(true);
        }
      })
      .catch(function (res) {});
  };

  return (
    <>
      <div className="App-header-rec">
        <div className="container text-start">
          <NavLink exact to="/login" className="nav-link">
            <BsIcons.BsArrowLeftShort className="display-1" />
          </NavLink>
          <div className="center">
            <div className="col-md-6 col-12 ">
              {!mostarMsg ? (
                <form onSubmit={submitForm}>
                  {mostrarComponente && (
                    <>
                      {state.errorMsg && (
                        <div className="text-danger">{state.errorMsg}</div>
                      )}
                      <div className="mb-3 pt-5">
                        <small>Correo electrónico</small>
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
                          value={state.userInfo.email}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                      <br />
                      {recover ? (
                        <div className="d-grid  mb-4">
                          <button
                            type="submit"
                            className="btn app-button  text-white shadow"
                          >
                            Restablecer contraseña
                          </button>
                        </div>
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div className="spinner"></div>
                        </div>
                      )}
                    </>
                  )}
                </form>
              ) : (
                <div className="app-text text-center pt-5">
                  <h4>
                    La contraseña fue restablecida, habre tú correo electrónico
                    !!
                  </h4>
                </div>
              )}
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
